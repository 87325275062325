<template>
  <div class="filter-container">
    <div class="search-container" v-for="(item, index) in searchArr" :key="JSON.stringify(item.name) + index">
      <pop-com
        @itemChange="popItemChange"
        @registeredTimeGet="registeredTimeGet"
        :comData="item.name !== '注册时间' ? item : { ...item, timeArr: regTimeArr }"
        :comIndex="index"
        :allData="searchArr" />
    </div>
    <div class="btn-container">
      <el-button size="default" circle @click="filterSearch">
        <el-icon><Search /></el-icon>
      </el-button>
    </div>
  </div>
</template>

<script>
import { deepClone } from "@/util/common";
import popCom from "./pop-compont.vue";

export default {
  data() {
    return {
      //储存子组件向上事件
      comArr: [],
      savedFilterArr: [],
      //注册时间
      regTimeArr: [],
      searchArr: [
        //过滤器保存条件数组
        {
          valueArr: [],
          scope: { value: "", name: "" },
          name: "",
          value: ""
        }
      ],
      //全部数据
      filterData: []
    };
  },
  props: ["index1", "data"],
  components: {
    popCom
  },
  mounted() {
    let data = sessionStorage.getItem("filterData", this.filterData);
    this.filterData = JSON.parse(data);
    this.searchArr = this.data.data;
    let savedData = sessionStorage.getItem("savedFilterArr");
    if (savedData) {
      this.savedFilterArr = JSON.parse(savedData);
    }
  },
  methods: {
    popItemChange(data) {
      //因为可能出现两个盒子同时删除或者触发删除后修改 因此使用一个数组维护逻辑
      if (data.type === "replace") {
        this.searchArr = data.data;
        this.sendData();
        return;
      }
      if (data.type === "regTime") {
        this.searchArr[data.index] = data.data;
        if (data.index === this.searchArr.length - 1) {
          this.searchArr.push({
            valueArr: [],
            scope: { value: "", name: "" },
            name: "",
            value: ""
          });
        }
        this.sendData();
        return;
      }
      if (data.type === "add") {
        this.comArr.push(data);
      } else if (data.type === "update") {
        this.comArr.push(data);
      } else if (data.type === "delete") {
        this.comArr.unshift(data);
      }
      setTimeout(() => {
        this.dealComArr();
      }, 2);
    },
    dealComArr() {
      let preIndex = "";
      //this.comArr长度最多为2 用于记录以及打开第一处弹框后点击删除了其他项时的情况 记录第一个删除删除index 用于第二个元素计算使用
      while (this.comArr.length > 0) {
        let data = this.comArr.pop();
        if (data.type === "add") {
          this.searchArr[data.index] = data.data;
          this.searchArr.push({
            valueArr: [],
            scope: { value: "", name: "" },
            name: "",
            value: ""
          });
        } else if (data.type === "update") {
          this.searchArr[data.index] = data.data;
        } else if (data.type === "delete") {
          if (preIndex === "") {
            preIndex = data.index;
            if (this.searchArr[preIndex].name === "注册时间") {
              this.regTimeArr = [];
            }
            this.searchArr.splice(data.index, 1);
          } else {
            if (preIndex > data.index) {
              //上一个删除的元素index在这个之后
              this.searchArr.splice(data.index, 1);
            } else {
              //上一个删除的元素index在这个之前 所以index要减一
              this.searchArr.splice(data.index--, 1);
            }
          }
        }
      }
      this.comArr = [];
      this.sendData();
    },
    clearLocalSearch() {
      //清空当前条件
      this.$refs["regTimeCom"].timeArr = ["", ""];
      this.regTimeArr = [];
      this.searchArr = this.searchArr.slice(-1);
      this.sendData();
    },
    registeredTimeGet(val) {
      //保存注册时间
      this.regTimeArr = val;
      this.sendData();
    },
    filterSearch() {
      //点击搜索按钮 将数据传递给父组件
      setTimeout(() => {
        let obj = deepClone({
          data: this.searchArr,
          reg: this.regTimeArr.map((item) => {
            return this.timeSystemFormat(item);
          })
        });
        this.$emit("searchStart", { data: obj, index: this.index1 });
      }, 10);
    },
    sendData() {
      setTimeout(() => {
        let obj = deepClone({
          data: this.searchArr,
          reg: this.regTimeArr.map((item) => {
            return this.timeSystemFormat(item);
          })
        });
        this.$emit("sendData", { data: obj, index: this.index1 });
      }, 10);
    }
  }
};
</script>

<style lang="less">
/*//组件内部的样式不能在scoped内部*/
@rem: 0.01rem;
.el-popover{padding:0}
</style>

<style lang="less" scoped>
@rem: 0.01rem;

@font-face {
  font-family: "iconfont";
  src: url("../assets/common/iconfont.ttf") format("truetype");
}
.filter-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  background: white;
  padding: 10 * @rem;
  padding-bottom: 5 * @rem;
  overflow: hidden;

  .search-container {
    border-radius: 26px;
    overflow: hidden;
  }
  .btn-container {
    margin-left: auto;
    .search-icon,
    .reload-icon {
      display: inline-block;
      width: 28 * @rem;
      height: 28 * @rem;
      font-size: 14 * @rem;
      line-height: 28 * @rem;
      border: 1 * @rem solid #8a8a8a;
      text-align: center;
      color: #8a8a8a;
      font-family: iconfont;
      margin-left: 10 * @rem;
      cursor: pointer;
    }
  }
}
@media screen and(min-width: 501px) {
  .filter-container {
    .search-group-container {
      .close-btn {
        display: none;
      }
      &:hover > .close-btn {
        display: block;
      }
    }
  }
}
@media screen and (max-width: 500px) {
  .filter-container {
    .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
      width: 150 * @rem;
      margin-right: 10 * @rem;
    }
    width: calc(100% - 40 * @rem);
    .search-group-container {
      max-width: 90%;
      .condition-show {
        max-width: 90vw;
      }
    }
  }
}
</style>
