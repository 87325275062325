<template>
  <el-config-provider :message="config">
    <router-view />
  </el-config-provider>
</template>
<script lang="ts" setup>
import { reactive } from "vue";
const config = reactive({
  max: 1
});
</script>
<style>
@import "./css/common.css";
body,
html {
  height: 100%;
  background: #222d32;
}
#app {
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background: #ecf0f5;
}
</style>
