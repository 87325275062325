const cn = {
  Cohort: "用户群组",
  DetailData: "详细数据",
  ErrorPage: "错误页面",
  TagManage: "标签管理",
  Analysis: "统计分析",
  ActiveUser: "活跃用户",
  Retention: "用户留存",
  Revenue: "收入分析",
  UAData: "买量日报",
  UADataAD: "买量日报(含广告)",
  UADataOS: "买量日报(海外)",
  UADataMerge: "买量日报(merge)",
  DailyReport: "数据日报",
  View: "View",
  Calculate: "计算",
  DNUReport: "DNU日报",
  DAUReport: "DAU日报",
  IAPRevenue: "IAP收入",
  TimeYesterday: "昨日此时",
  YesterdayTotal: "昨日合计",
  ImportData: "导入数据",
  Upload: "点击上传",
  UploadAFile: "上传文件",
  UploadingOldFiles: "上传老文件",
  EnterPageName: "请输入页面名称",
  Cancel: "取消",
  Comfirm: "确定",
  ExportAllGroup: "导出所有分组",
  InputAllGroup: "导入所有分组",
  DataSourceManage: "数据源管理",
  DataSource: "数据源",
  AddPage: "添加page",
  AddTable: "添加图表",
  DataName: "数据名",
  CreateDataSource: "新建数据源",
  PayCountByUsers: "付费分布-人数",
  RevenueByUsers: "付费分布-金额",
  PayCountByAdUsers: "付费分布-广告人数",
  RevenueByAdUsers: "付费分布-广告金额",
  AccumulatedIAPrevenue: "IAP 累计收入",
  AccumulatedPayCount: "IAP 累计人数",
  IAPConversion: "IAP转化率",
  ARPPU: "ARPPU",
  IAPARPDAU: "IAPARPDAU",
  ValueExplain1: "所有值为分数的左上角指标",
  ValueExplain2: "计算方式均为(每日分子之和/每日分母之和),不涉及去重",
  D2RetentionByCountry: "次留-国家或地区",
  D7RetentionByCountry: "7留-国家或地区",
  D30RetentionByCountry: "30留-国家或地区",
  D2RetentionByPlatform: "次留-平台",
  D7RetentionByPlatform: "7留-平台",
  D30RetentionByPlatform: "30留-平台",
  ClickShowEcharts: "点击表格行展示echarts",
  ExportData: "导出",
  Export: "导出",
  ExportFilteredData: "导出筛选数据",
  UniqueDAU: "所选时间去重DAU",
  CumulativeDNU: "所选时间累加DNU",
  AverageDAU: "所选时间平均DAU",
  Last14Days: "最近14天",
  Last30Days: "最近30天",
  Last90Days: "最近90天",
  Last21Days: "最近21天",
  Last60Days: "最近60天",
  ThisMonth: "这个月",
  LastMonth: "上个月",
  LastWeek: "最近7天",
  AllRegisteredUsers: "所有注册用户",
  StartTime: "开始时间",
  EndTime: "结束时间",
  Yesterday: "昨天",
  Document: "技术文档",
  ChangePassword: "更改密码",
  Logout: "退出",
  Login: "登陆",
  SetUp: "设置",
  SystemManage: "应用设置",
  AccountManage: "账号管理",
  RoleManage: "角色管理",
  Logs: "请求日志",
  RealtimeData: "实时数据",
  See: "查看",
  Edit: "编辑",
  CalculateEchart: "计算报表",
  RuleManage: "规则管理",
  Lists: "列表",
  Add: "新增",
  Delete: "删除",
  TeamManage: "团队管理",
  PushManage: "推送管理",
  Catalog: "内购管理",
  GoodsDetails: "商品详情",
  UserQuery: "用户管理",
  UserQueryByAccounts: "账号查询",
  UserQueryByDevices: "设备查询",
  PicturesManage: "图片管理",
  CustomerServiceSystem: "客服系统",
  SensitiveWord: "敏感词",
  UserMessages: "消息列表",
  CustomerManage: "客服管理",
  TagsManage: "标签管理",
  Album: "相册",
  TeamRank: "团队排行榜",
  RetentionLine: "留存曲线",
  GiftPurchasesCount: "每日购买礼包数",
  Hours: "小时",
  Minutes: "分",
  Today: "今天",
  OneDay: "天",
  ActiveUsers: "活跃用户数",
  Conversion: "转化率",
  Platform: "平台",
  Version: "版本",
  AssetVersion: "资源版本",
  Version2: "初始版本",
  AssetVersion2: "初始资源版本",
  Channel: "用户渠道",
  Tag: "标签",
  Include: "包含",
  CustomerId: "用户Id",
  Select: "选择范围",
  Android: "安卓",
  IOS: "苹果",
  EnterTag: "请输入自定义标签",
  QuickSearch: "快捷搜索",
  SaveFilter: "保存查询",
  Save: "保存",
  SavedFilters: "已保存条件",
  And: "和",
  ClearSearch: "清空查询",
  ClearESSearch: "清空es查询",
  Back: "返回",
  AdRevenue: "广告收入",
  TotalAdRevenue: "广告累计收入",
  Update: "更新",
  Create: "创建",
  Sort: "排序",
  Rule: "规则",
  File: "文件",
  Attribute: "属性",
  CumulativePayCount: "IAP累计人次",
  ConfigManage: "配置文件管理",
  FileCompare: "文件对比",
  D2Retention: "次留",
  D7Retention: "7日留存",
  D30Retention: "30日留存",
  LevelTable: "关卡统计",
  GiftData: "礼包日报",
  PayCountByValue: "当日付费人数-当日价值",
  PayCountByInstall: "累计付费金额",
  TimeZone: "时区",
  Date: "日期",
  QuickReplyManage: "快捷回复管理",
  RefreshFilterData: "刷新filter数据",
  Account: "账户",
  Echarts: "图像",
  Table: "表格",
  "7Day": "7天",
  "14Day": "14天",
  "30Day": "30天",
  "90Day": "90天",
  "180Day": "180天",
  UpToNow: "至今",
  Range: "范围",
  Background: "背景",
  FilterConditions: "筛选条件",
  PackagesPurchasedPerDayNum: "每天购买礼包数",
  PackageConversionRate: "礼包转化率",
  PackageConversionRatePercentile: "礼包转化率百分位",
  Chinese: "中文",
  English: "英文",
  RetentionAverage: "留存均值",
  Day: "天",
  AddContrast: "添加对比",
  SearchAll: "查询全部",
  EchartsType: "图表类型",
  GroupSelection: "数据选择",
  GroupChooseAll: "数据全选",
  SearchSuccess: "查询成功",
  GroupNoData: "当前组无数据",
  GetNoData: "在当前条件下没有获取到数据",
  AtLeastOnConditions: "至少需要一组条件",
  AreYouSureDelete: "确定删除该数据吗？",
  Tips: "提示",
  DataRefreshError: "数据刷新报错,请稍后再试",
  CustomReport: "自定义分析",
  MaterialManagement: "物料管理",
  MaterialClassification: "物料分类",
  PropertyManagement: "属性管理",
  PropertyValueManagement: "属性值管理",
  DownloadPassword: "下载的压缩包解压密码是",
  DeleteAccount: "确定删除该账号吗?",
  UnzipPassword: "解压密码",
  CopyPassword: "复制密码",
  OsDataDownName: "买量日报(海外)筛选后数据",
  GetMenuError: "获取目录与appId失败,请重试",
  DataType: "数据类型",
  Source: "来源",
  Data: "数据",
  DataSourceName: "数据源名称",
  DataSourceUrl: "数据源地址",
  RequestSuccess: "请求成功",
  UnfilledTip: "您有数据未填写",
  FormName: "报表名称",
  BelongPage: "所属页面",
  FormParameter: "表格参数",
  FormParameterCase: "创建格式为 label-key 例如: 最大关卡-max_level",
  XDisplay: "x轴显示",
  YDisplay: "y轴显示",
  ShowType: "显示类型",
  Number: "数值",
  Percentage: "百分比",
  SureRefreshPassword: "确定重置密码吗 ?",
  PieBasis: "饼状-基础",
  Pie: "饼状图",
  LineChart: "折线图",
  NoPermission: "没有权限",
  EsInterfaceError: "es接口获取数据失败",
  ModifiedSuccess: "修改成功",
  ModifiedError: "修改失败",
  SaveSuccess: "保存成功",
  ConditionNameNeed: "未填写条件名",
  SaveError: "保存失败",
  DeleteSuccess: "删除成功",
  DeleteError: "删除失败",
  ParameterErrorUpload: "参数错误，请刷新后再上传",
  FileBig: "文件太大",
  UploadFailed: "上传失败",
  UnfileStatus: "请先取消父规则的归档状态",
  EditSortSucceeded: "编辑排序成功",
  ParameterError: "参数错误",
  TeamIdNotExist: "teamId不存在",
  NoSuchMemberExists: "不存在该成员",
  OperationSuccessful: "操作成功",
  OperationFailed: "操作失败",
  AppIdCannotBeEmpty: "appId不能为空",
  MessageNotBeEmpty: "Message content不得为空",
  CreateSuccess: "创建成功",
  CreateFailed: "创建失败",
  FileNotEmpty: "文件名称不得为空",
  JsonEmpty: "json数据为空",
  GetSuccess: "获取成功",
  TrueTagId: "请输入正确的tagId",
  HighThanLow: "高版本需大于低版本",
  ContentIdNeed: "contentId不能为空",
  TagNameNeed: "Tag名不能为空",
  TagNameAlreadyExists: "Tag名已存在",
  UserDoesNotExist: "用户不存在",
  EsVersionFail: "es查询小版本号失败",
  AlbumNameNeed: "未填写相册名",
  AlbumNameExist: "相册名称已存在",
  UploadSuccess: "上传成功",
  PicShouldBeSmall: "图片需小于500K",
  SendFail: "发送消息失败",
  SendSuccess: "发送成功",
  MessageVerificationFailed: "消息校验失败",
  TransformContentEmpty: "翻译的内容不能为空",
  TranslateSuccess: "翻译成功",
  TranslateFail: "翻译失败，请稍后重试！",
  BindSuccess: "绑定成功",
  BindFail: "绑定失败",
  TagBinded: "标签已绑定",
  SensitiveWordsNeed: "敏感词不得为空",
  ImportedSuccess: "成功导入条数:",
  EnterEmailAccount: "请输入邮箱账号!",
  EmailExists: "邮箱已经存在!",
  SelectApp: "请选择至少一个app!",
  EmailSendFail: "邮件发送失败",
  UserIdNeed: "userId不得为空",
  RoleCanNotModify: "管理员和超级管理员无法修改！",
  RoleCanNotDelete: "管理员和超级管理员无法删除！",
  UserCanNotDelete: "角色下存在用户，无法删除!",
  NeedLogin: "需要登陆",
  ReqFail: "请求有误",
  DeletePlayerConfirm: "确定删除该玩家吗?",
  FilterDataRefreshSuccess: "刷新filter数据成功",
  ModifyGridSuccess: "修改布局成功",
  PageReplacemenSuccess: "更换页面成功",
  EditingDataSucceeded: "编辑数据成功",
  RemoveCurrent: "请移除当前文件后再上传",
  DownloadSuccess: "下载成功",
  CopySuccess: "复制成功",
  NumberOfMatches: "匹配人数",
  NumberOfSuccessfulPush: "推送成功人数",
  Operation: "操作",
  CreateNewPush: "创建新推送",
  PushCampaignIdNotExist: "pushCampaignId不存在",
  ModifiedLocationSuccess: "修改位置成功",
  AddedSuccess: "添加成功",
  UnbindSuccess: "解绑成功",
  UnbindFail: "解绑失败",
  ResetSuccess: "重置成功",
  RuleManagePrompt: "一级规则排序是从下向上的。子规则排序是从右到左的。如果子规则使用权重则只会命中一个。",
  AddCondition: "添加条件",
  AddRule: "添加规则",
  WeightNeed: "权重值不能为空",
  Stored: "已存储",
  LatestSensitiveWord: "最新录入的敏感词",
  SenUnit: " 条",
  InputFormat: "输入格式",
  Or: "或",
  EnterCorrectFormat: "请输入正确的格式",
  Entry: "录入",
  SensitivePrompt: "注意 : 标点符必须统一为英文, 不能混用",
  ChineseAndEnglishPunctuation: "中英文标点",
  BasisEcharts: "基础图",
  RegionalFigure: "区域图",
  SmoothFigure: "平滑图",
  Histogram: "柱状图",
  ChooseRegion: "选择国家或地区",
  ChooseAll: "全选",
  Introduce: "简介",
  NoIntroduce: "暂无简介",
  Close: "关闭",
  FunnelPlot: "单个柱状漏斗图",
  Download: "下载",
  DataFile: "数据文件",
  SaveSearchCriteria: "保存搜索条件",
  EnterAlias: "请输入别名",
  GetFilterDataFail: "获取filter数据失败",
  CreateAtLeastOneCondition: "请至少创建一个条件",
  RegistTime: "注册时间",
  To: "到",
  CustomConditionManagement: "自定义条件管理",
  CurrentConditionHasBeenCreated: "当前条件已经创建",
  CurrentClassConditionHasBeenCreated: "已经创建当前分类条件",
  TableData: "表格数据",
  ReverseChoice: "反选",
  Contrast: "对比",
  Refresh: "刷新",
  DataView: "数据视图",
  FormDocument: "表格文档",
  TableDNU: "数据日报DNU",
  TableDAU: "数据日报DAU",
  Dimension: "维度",
  SecondDimension: "第二维度",
  AreYouSureDeleteAll: "确定删除全部数据吗？",
  DeleteAll: "删除全部",
  SingleStoreDetails: "单个store详细数据",
  NameNeed: "name不能缺少",
  ProductIdNeed: "product_id不能缺少",
  PriceNeed: "price不能缺少",
  ProductTypeNeed: "productType不能缺少",
  StoreNeed: "store不能缺少",
  StatusNeed: "status不能缺少",
  Yes: "是",
  No: "否",
  ConsumableNeed: "consumable不能缺少",
  SubscriptionNeed: "subscription不能缺少",
  CurrencyAmountNeed: "currency amount不能缺少",
  CurrencyNeed: "currency不能缺少",
  CreateProduct: "新建Product",
  EditProduct: "编辑Product",
  SystemData: "系统数据",
  CustomData: "自定义数据",
  AddData: "添加数据",
  EditData: "编辑数据",
  LineChartBasis: "折线图-基础",
  LineChartBasisSmooth: "折线图-基础-光滑",
  LineChartArea: "折线图-区域",
  LineChartBasisStack: "折线图-基础堆叠",
  LineChartBasisSmoothStack: "折线图-基础平滑堆叠",
  LineChartAreaStack: "折线图-区域堆叠",
  HistogramLongitudinal: "柱状图-纵向",
  HistogramTransverse: "柱状图-横向",
  HistogramFunnel: "柱状图-横向",
  TableBasis: "基础表格",
  TableDataType: "数据表格",
  Time: "时间",
  Num: "指数",
  ChartNameNeed: "请输入报表名称",
  ChartTypeNeed: "请选择图表类型",
  DataSourceNeed: "请选择数据源",
  XTypeNeed: "请选择x轴显示",
  YTypeNeed: "请选择y轴显示",
  GroupName: "分组名称",
  ModifyGroup: "修改分组",
  PageGroup: "页面分组",
  AddGroup: "添加分组",
  PageName: "页面名称",
  ErrPageTip: "找不到对对应页面，跳转失败",
  CreateAlbum: "创建相册",
  AlbumName: "相册名",
  PictureDrag: "将文件拖到此处，或",
  ClickToUpload: "点击上传",
  ReviewPicture: "查看图片",
  CreateFile: "新建文件",
  CreateTag: "新建Tag标记",
  TagList: "tag-列表",
  LatestFile: "最新的文件",
  PleaseChoose: "请选择",
  All: "全部",
  SelectTag: "选中tag",
  ConfigurationFileName: "配置文件名称",
  Submit: "提交",
  FillRemark: "填写必要备注",
  CloseCompare: "关闭对比",
  OpenCompare: "打开对比",
  AddFile: "添加文件",
  FileNmae: "文件名",
  AddTag: "添加Tag标记",
  FileNameNeed: "请填写文件名",
  ChooseLineOrCreateFile: "请选中任意行或新建文件后继续提交",
  RemarkNeed: "请填写备注后继续提交",
  LowVersion: "低版本",
  HeightVersion: "高版本",
  FileCompareExplain: "点击标题可收放当前文件内容，切换为两列时，左边为老版本，右边为新版本",
  HightThenLower: "高版本需要高于低版本",
  HaveNoModify: "当前两个版本无修改",
  NewUsers: "新增用户",
  AddPayingUsers: "新增付费用户",
  NewUsersRevenue: "新增用户收入",
  TodayRevenue: "今日收入",
  LuaRErrorLogDevicesevenue: "Lua错误日志设备数",
  LuaRErrorLogTotal: "Lua错误日志总数",
  RErrorLogDevicesevenue: "错误日志设备数",
  RErrorLogTotal: "错误日志总数",
  PayUsersNum: "付费用户数",
  DailyRegisteredUsersGroupByChannel: "日注册用户按渠道分组",
  DailyActiveGroupByChannel: "日活按渠道分组",
  DailyRegistUserGroupedByCountryorRegion: "日注册用户按国家或地区分组",
  DailyActiveUserGroupedByCountryorRegion: "日活跃用户按国家或地区分组",
  DailyRegistUserGroupedByUA: "日注册用户按UA素材组分组",
  DailyActiveUserGroupedByUA: "日活按UA素材组分组",
  DailyActiveUserGroupedByDay_n: "每日活跃用户按day_n分类",
  DNUGroupedByType: "DNU按转化类型",
  DauDealData: "dau按版本按天,剔除掉所选时间范围内特定版本总人数只占所有版本总人数万一的数据",
  DNUChannel: "DNU-渠道",
  DAUChannel: "DAU-渠道",
  DNUCountryOrRegion: "DNU-国家或地区",
  DAUCountryOrRegion: "DAU-国家或地区",
  DAUDistribution: "DAU-分布",
  DAUConversionType: "DAU-转换类型",
  DAUVersion: "DAU-版本",
  RetainGroupByDay: "留存按天分组，注册用户为当天注册并在当天符合筛选条件的用户(之后符合条件不计算在内)",
  RetainGroupByCountryOrRegion:
    "留存按国家或地区分组，注册用户为当天注册并在当天符合筛选条件的用户(之后符合条件不计算在内)",
  RetainGroupByPlatform: "留存按平台分组，注册用户为当天注册并在当天符合筛选条件的用户(之后符合条件不计算在内)",
  PayCountTotal: "累计付费用户数",
  ConversionPaid: "转化率，付费用户/日活",
  TimeRangeRevenue: "广告所选时间范围内的累计收入",
  DailyADArpdau: "当日广告收入的arpdau",
  PayUserArev: "付费用户平均付费金额，付费总额/付费用户数",
  PayGroupedByPlatform: "付费金额按平台分组",
  AverageDailyPayment: "日活用户平均付费金额，付费总额/日活用户数",
  AdvertisingRevenueByChannelByDay: "广告收入按渠道按天",
  DistributionOfPayersOnDayN: "第n天付费人数分布",
  DistributionOfpaymentAmountOnDayN: "第n天付费金额分布",
  DailyPayUsersStatistical: "当日付费人数按金额统计",
  IAPStatisticalByPayAmount: "IAP按累计付费金额统计",
  IAPStatisticalByPayNumber: "IAP按累计付费次数统计",
  FeesAreGroupedByCountryOrRegion: "付费金额按国家或地区分组",
  ToTalFeesAreGroupedByCountryOrRegion: "累计付费金额按国家或地区分组",
  ToTalPayUsersAreGroupedByCountryOrRegion: "累计付费用户数按国家或地区分组",
  ConversionRateGroupedByCountryOrPgion: "转化率按国家或地区分组，付费用户/日活",
  ARPPUByCountryOrRegionByDay: "arppu按国家或地区按天",
  ARPDAUByCountryOrRegionByDay: "arpdau按国家或地区按天",
  DNUDailyReportLineHeaderDateRegistrationDate: "DNU数据日报行首日期注册日期",
  DAUDailyReportLineHeaderDateActiveDate: "DAU数据日报行首日期活跃日期",
  LifetimeValue: "终身价值",
  RevenueTable: "留存表",
  ToTalPayer: "付费人数",
  TotalROI: "累计ROI",
  RevenueWord: "收入",
  ChannelWord: "渠道",
  CountryOrRegion: "国家或地区",
  CumulativeROICurve: "累计roi曲线，计算方式:累计收入/累计成本",
  SelectDate: "选择日期",
  AnyPointInTime: "任意时间点",
  UTCTime: "UTC时间",
  LocalTime: "本地时间",
  JiGuang: "极光推送",
  Push: "推送",
  CreatePush: "创建推送",
  EditPush: "编辑推送",
  DailyUserSendNum: "每日用户发送次数",
  CampaignNameNeed: "请输入campaignName",
  UserRuleIdNameNeed: "请选择userRuleId",
  UtcOrLocalNeed: "请选择utcOrLocal",
  FrequencyCappingNeed: "请选择frequencyCapping",
  NeedContent: "需要一组content数据",
  AtLeastNeedOne: "至少需要一组数据",
  DNU: "日注册用户",
  DAU: "日活跃用户",
  RevenueData: "留存数据",
  SelectDateTime: "选择日期时间",
  SelectPlatform: "选择平台",
  EditCurrentRule: "编辑规则",
  AddTopRule: "添加顶级规则",
  SelectOptions: "选填",
  EditCondition: "编辑条件",
  NeedRuleName: "ruleName不能缺少",
  NoCorrespondingNameFound: "未找到对应名称",
  CloseOpenRule: "请将已经展开的规则收起",
  DragWarning: "必须Status状态为All或者ON，OFF同时开启，Schedule为全选才能排序",
  EditJson: "编辑json",
  CreateJson: "新增json",
  Question: "问题",
  Answer: "答案",
  Keywords: "关键词",
  NewLineFormat: "换行请使用<br>",
  KeyWordsSplit: "多个关键词请使用英文,分开",
  QuestionNeed: "请输入问题",
  AnswerNeed: "请输入答案",
  AddLabelTag: "新增标签",
  TagNeed: "请输入标签",
  LoadMore: "-- 加载更多 --",
  NoMore: "没有更多了",
  NoMoreMessage: "还没有更多消息",
  User: "用户",
  Service: "客服",
  ChineseSimplified: "中文(简体)",
  ChineseTraditional: "中文(繁体)",
  NeedSendMessage: "未填写发送信息",
  ServiceData: "客服数据.csv",
  ServiceTip: "客服规则小提示",
  ServiceTip1: "- 点击账号配置客服服务地区,可用于分配客服;不配置,默认分配所有用户",
  ServiceTip2: "- 双击权重编辑",
  ServiceTip3: "- 客服回复权限在角色管理中控制",
  ServiceTip4: "- 客服消息,刷新页面默认过滤国家",
  ServiceWeight: "客服权重值",
  ServiceTip5: "- 客服分配权重值，值越大分配到的可能性越高 双击编辑",
  EditWeight: "编辑权重",
  FuzzyQueryByEmail: "通过email模糊查询",
  UserStatus: "用户状态",
  Inactive: "未激活",
  Active: "已激活",
  ExpirationTime: "过期时间",
  NeverExpire: "永不过期",
  CreateTime: "创建时间",
  UpdateTime: "更新时间",
  ExtendExpirationTime: "延长过期时间",
  Modify: "修改",
  ModifyStatus: "修改状态",
  Activation: "激活",
  Deactivate: "取消激活",
  EditAccount: "编辑账户",
  CreateAccount: "创建账户",
  ModifyExpirationTime: "修改过期时间",
  Unset: "(未设置)",
  SystemDefault: "是否系统默认",
  RoleName: "角色名",
  Note: "备注",
  CreateRole: "创建角色",
  EditRole: "编辑角色",
  SureDeleteRole: "确定删除该角色吗?",
  BasicInformation: "基础信息",
  Dollar: "$(美元)",
  RMB: "¥(日元/人民币)",
  China: "中国",
  Japan: "日本",
  American: "美国",
  ChooseCountry: "请选择国家",
  AllCountry: "全部国家",
  Global: "全球",
  TOP100Teams: "团队排行榜TOP100",
  TOP100Individual: "个人排行榜TOP100",
  TeamNameOrIdNeed: "请输入团队名称或者teamId号",
  TeamName: "团队名称",
  TeamMemberNum: "团队人数",
  Score: "分数",
  Country: "国家",
  MemberList: "成员列表",
  ModifyPlayerInfo: "修改玩家信息",
  PlayerState: "玩家状态",
  Normal: "正常",
  Silence: "禁言",
  PlayerScore: "玩家分数",
  PlayerName: "玩家名称",
  JoinTime: "加入时间",
  IsLeader: "是否队长",
  State: "状态",
  SureMakeThisPlayerToBeTeamLeader: "确定将该玩家置为队长吗 ?",
  NeedReview: "需要审核",
  NoReview: "不需要审核",
  Season: "赛季",
  AchieveLevel: "达成等级：",
  RealNameSystem: "是否实名制",
  CheckPoint: "关卡",
  NumberOfFriends: "好友总数",
  UnbindEquipment: "解绑设备",
  UnbindPhoneNum: "解绑手机",
  InviteCodeLevel: "填写邀请码的等级",
  CurrentLevel: "当前等级",
  TargetLevelSpanTime: "达成目标关卡累计消耗的时间",
  TargetLevelAverageScore: "达成目标关卡平均分数",
  HighDollarGet: "美钞最高获得",
  HightChengjianCoinGet: "城建币最高获得",
  IDCard: "身份证号码",
  CumulativePayAmount: "累计充值金额",
  EquipmentNumber: "设备号",
  MSG: "MSG(错误详细信息)",
  SearchBy: "通过ThirdBind或DeviceId查询",
  AKeyUnbind: "一键解绑",
  CustomerIdSearch: "customerId搜索",
  RequestDetailedDataFail: "请求详细数据失败",
  CampaignName: "campaign 名称",
  PurchaseChanel: "买量",
  NaturalChannel: "自然量",
  Week: "周",
  Month: "月",
  Year: "年",
  StoreAlreadyExist: "商店已存在",
  DNUName: "DNU",
  DAUName: "DAU",
  NeedValue: "请填写value",
  UnbindThird: "解绑第三方",
  CurrentNoWord: "当前无文字",
  ZipNotExist: "压缩包不存在",
  ZipCreateFailed: "压缩包创建失败",
  ZipNameAlreadyExist: "压缩包名称已存在",
  DNUConversionType: "DNU-转换类型",
  CreateZipFile: "创建ZIP文件",
  Description: "描述",
  Name: "名称",
  ChooseZipTip: "请选择一个文件后再生成zip文件",
  VipList: "会员列表",
  BirthDay: "生日",
  Address: "地址",
  BindingTime: "绑定时间",
  Phone: "手机号",
  KeyManage: "密钥管理",
  AppIdDoesNotExist: "appId不存在",
  VerifyCode: "验证码",
  VerifyCodeHasExpired: "验证码已过期",
  VerifyCodeError: "验证码错误",
  VerifyCodeHasBeenSent: "验证码已发送至邮箱",
  KeyHasBeenAdded: "密钥已被添加",
  EnterVerifyCode: "请输入验证码",
  ShowAppSecret: "展示AppSecret",
  Continue: "继续",
  VerifyCodeWillBeSent: "验证码将发送至您的邮箱进行验证, 是否继续?",
  AppVersionFormatError: "版本号格式错误",
  AppSecretFormatError: "密钥格式错误",
  ParameterCannotBeEmpty: "参数不能为空",
  PleaseEnter32Key: "请输入32位密钥",
  PleaseEnterAppVersion: "请输入版本号,例 1.7.8",
  ShopName: "花店名称",
  DeviceType: "设备型号",
  AMonthPayAmount: "30天充值金额",
  NowDollar: "剩余钞票",
  NowBalance1602: "剩余城建币",
  LastLoginTime: "最后登录时间",
  HalfYearActiveDays: "半年活跃天数",
  FailedNumberOfLastFiveLevels: "近5关失败数",
  AccountType: "账号类型",
  WithdrawTheMessage: "撤回消息",
  BannedDays: "封禁天数",
  PleaseSelectOrEnterDays: "请选择或是输入天数",
  Nothing: "暂无",
  InputFormatCustomerID: "输入格式: customerId1,customerId2,customerId3...",
  BatchEdit: "批量修改",
  DaysFormatError: "天数格式错误",
  BannedIn: "封禁中",
  HasUnsealed: "已解封",
  FreezeList: "冻结列表",
  InSearchTips: "正在查询中，请稍后！",
  BattleStart: "对战房间总数",
  RoomNum: "实时房间数",
  GoRErrorLogTotal: "go服务端错误日志数",
  YesterdayPeak: "昨日峰值",
  QueryTimeTo: "查询至XX天后",
  DeleteWord: "输入需要删除的敏感词",
  InputValue: "请输入敏感词",
  AccountDoesNotExist: "帐户不存在",
  UsernameOrPasswordError: "账号密码错误"
};
const en = {
  AccountDoesNotExist: "账号密码错误",
  InputValue: "Place enter the word",
  DeleteWord: "Enter the word you want to delete",
  QueryTimeTo: "Query Time To XX Day ",
  YesterdayPeak: "YesterdayPeak",
  BattleStart: "BattleStart",
  RoomNum: "RoomNum",
  GoRErrorLogTotal: "GoRErrorLogTotal",
  InSearchTips: "Query in progress, please wait!",
  FreezeList: "Freeze List",
  HasUnsealed: "Has unsealed",
  BannedIn: "Banned in",
  DaysFormatError: "Banned Days Format Error",
  BatchEdit: "Batch Edit",
  InputFormatCustomerID: "Input format: customerId1,customerId2,customerId3...",
  Nothing: "Nothing",
  PleaseSelectOrEnterDays: "Please select or enter days",
  BannedDays: "Banned days",
  WithdrawTheMessage: "Withdraw the message",
  AccountType: "Account Type",
  FailedNumberOfLastFiveLevels: "Failed number of last 5 levels",
  HalfYearActiveDays: "Half-Year Active Days",
  LastLoginTime: "Last Login Time",
  NowBalance1602: "Now Banlance_1602",
  NowDollar: "Now Dollar",
  AMonthPayAmount: "A Month Pay Amount",
  DeviceType: "Device Type",
  ShopName: "Shop Name",
  PleaseEnterAppVersion: "Please enter the AppVersion,case 1.7.8",
  PleaseEnter32Key: "Please enter a 32-bit key",
  ParameterCannotBeEmpty: "The parameter cannot be empty",
  AppSecretFormatError: "AppSecret format error",
  AppVersionFormatError: "AppVersion format error",
  VerifyCodeWillBeSent: "Verification code will be sent to your email. Do you want to continue?",
  Continue: "continue",
  ShowAppSecret: "Show AppSecret",
  EnterVerifyCode: "Please Enter Verify Code",
  KeyHasBeenAdded: "The key has been added",
  VerifyCodeHasBeenSent: "The code has been sent to the mailbox",
  VerifyCodeError: "The code error",
  VerifyCodeHasExpired: "The code has expired",
  VerifyCode: "Verify Code",
  AppIdDoesNotExist: "AppId does not exist",
  KeyManage: "Keys",
  Phone: "Phone",
  BindingTime: "Binding Time",
  Address: "Address",
  BirthDay: "BirthDay",
  VipList: "Vip List",
  ChooseZipTip: "Please choose file to create Zip",
  Name: "Name",
  Description: "Description",
  CreateZipFile: "CreateZipFile",
  DNUConversionType: "DNU Conversion Type",
  ZipNameAlreadyExist: "ZipName Already Exist",
  ZipCreateFailed: "Zip Create Failed",
  ZipNotExist: "Zip Not Exist",
  UnbindFail: "Unbind Fail",
  CurrentNoWord: "Current have no words",
  UnbindThird: "Unbind Third Party",
  NeedValue: "Please entry value",
  DNUName: "DNU",
  DAUName: "DAU",
  StoreAlreadyExist: "Store Already Exist",
  Week: "Week",
  Month: "Month",
  Year: "Yeat",
  PurchaseChanel: "Purchase Chanel",
  NaturalChannel: "Natural Channel",
  CampaignName: "Campaign Name",
  RequestDetailedDataFail: "Failed to request detailed data",
  CustomerIdSearch: "CustomerIdSearch",
  AKeyUnbind: "AKeyUnbind",
  SearchBy: "Search by the ThirdBind or DeviceId command",
  MSG: "MSG(error details)",
  InviteCodeLevel: "Fill in the level of the invite code ",
  CurrentLevel: "Current level ",
  TargetLevelSpanTime: "Target level span time",
  TargetLevelAverageScore: "Target level average score",
  HighDollarGet: "High dollar get",
  HightChengjianCoinGet: "hight Chengjian coin get",
  IDCard: "IDCard",
  CumulativePayAmount: "Cumulative pay amount",
  EquipmentNumber: "Equipment number",
  UnbindEquipment: "UnbindEquipment",
  UnbindPhoneNum: "UnbindPhoneNum",
  NeedReview: "Need review",
  NoReview: "No review",
  Season: "Season",
  AchieveLevel: "Achieve level:",
  RealNameSystem: "Real name system",
  CheckPoint: "CheckPoint",
  NumberOfFriends: "Number of friends",
  SureMakeThisPlayerToBeTeamLeader: "Are you sure to make this player the team leader ?",
  State: "State",
  MemberList: "Member list",
  ModifyPlayerInfo: "Modify player info",
  PlayerState: "player state",
  Normal: "normal",
  Silence: "Silence",
  PlayerScore: "player score",
  PlayerName: "Player name",
  JoinTime: "Join time",
  Score: "Score",
  Country: "Country",
  TeamMemberNum: "TeamMemberNum",
  TeamName: "TeamName",
  TeamNameOrIdNeed: "Please entry team name or teamID",
  TOP100Individual: "TOP100Individual",
  TOP100Teams: "TOP100Teams",
  Global: "Global",
  AllCountry: "AllCountry",
  ChooseCountry: "Please Select Country",
  American: "American",
  Japan: "Japan",
  China: "China",
  RMB: "¥(Yen/RMB)",
  Dollar: "$(dollar)",
  BasicInformation: "Basic Information",
  SureDeleteRole: "Are you sure to delete this role?",
  CreateRole: "CreateRole",
  EditRole: "EditRole",
  Note: "Note",
  RoleName: "RoleName",
  SystemDefault: "SystemDefault",
  Unset: "(Unset)",
  ModifyExpirationTime: "Modify Expiration Time",
  CreateAccount: "Create Account",
  EditAccount: "Edit Account",
  Deactivate: "deactivate",
  Activation: "activation",
  ModifyStatus: "Modify Status",
  Modify: "Modify",
  ExtendExpirationTime: "Extend Expiration Time",
  CreateTime: "Create Time",
  UpdateTime: "Update Time",
  NeverExpire: "Never Expire",
  ExpirationTime: "过期时间",
  UserStatus: "User Status",
  FuzzyQueryByEmail: "Fuzzy query by email",
  EditWeight: "Edit Weight",
  ServiceTip5:
    "- Customer service to assign weight value, the larger the value is, the higher the possibility of assigning to double click edit",
  ServiceWeight: "Customer service weight",
  ServiceTip1:
    "- Click the account to configure the customer service region, which can be used to assign customer service; If no, all users are assigned by default",
  ServiceTip2: "- Double-click the weight to edit",
  ServiceTip3: "- The customer service reply permission is controlled in role management",
  ServiceTip4: "- Customer service messages, refresh page by default filter country",
  ServiceTip: "Service Rule Tip",
  ServiceData: "ServiceData.csv",
  NeedSendMessage: "Have no send message",
  ChineseSimplified: "Chinese (Simplified)",
  ChineseTraditional: "Chinese (traditional)",
  User: "User",
  Service: "Custom Service",
  user: "user",
  NoMoreMessage: "Have No More Message Now",
  NoMore: "No More",
  LoadMore: "-- Load More --",
  TagNeed: "Please entry tag",
  AddLabelTag: "Add Tag",
  AnswerNeed: "Please entry answer",
  QuestionNeed: "Please entry question",
  KeywordsNeed: "Please entry keywords",
  KeyWordsSplit: "Please separate multiple keywords in English",
  NewLineFormat: "New line use <br>",
  Keywords: "Keywords",
  Answer: "Answer",
  Question: "Question",
  CreateJson: "Create Json",
  EditJson: "Edit Json",
  DragWaring: "The order can be sorted only when the Status is All or ON, OFF is enabled, and Schedule is set to All",
  CloseOpenRule: "Please close rule that have open",
  NoCorrespondingNameFound: "No corresponding name found",
  needRuleName: "Please entry rule name",
  EditCondition: "Edit Condition",
  SelectOptions: "Select Options",
  AddTopRule: "Add Top Rule",
  EditCurrentRule: "Edit Rule",
  SelectPlatform: "Select Platform",
  SelectDateTime: "Select Date Time",
  RevenueData: "Revenue Data",
  DNU: "Daily new user",
  DAU: "Daily active user",
  AtLeastNeedOne: "At least need one group data",
  NeedContent: "You need one group content data",
  CreatePush: "Create Push",
  EditPush: "Edit Push",
  FrequencyCappingNeed: "Please select frequencyCapping",
  CampaignNameNeed: "Please entry campaignName",
  UserRuleIdNameNeed: "Please select userRuleId",
  UtcOrLocalNeed: "Please select UtcOrLocal",
  DailyUserSendNum: "Number of sent messages per day",
  Push: "Push",
  JiGuang: "JiGuangPush",
  LocalTime: "Local Time",
  UTCTime: "UTC Time",
  AnyPointInTime: "Any point in time",
  SelectDate: "Select Date",
  CumulativeROICurve: "Cumulative ROI curve, calculated as: cumulative revenue/cumulative cost",
  CountryOrRegion: "Country | Region",
  ChannelWord: "Channel",
  RevenueWord: "Revenue",
  TotalROI: "Rolling ROI",
  ToTalPayer: "Payers",
  RevenueTable: "Revenue",
  LifetimeValue: "LTV",
  DAUDailyReportLineHeaderDateActiveDate: "DAU data daily line first date Active date",
  DNUDailyReportLineHeaderDateRegistrationDate: "DNU daily report line header date registration date",
  ARPDAUByCountryOrRegionByDay: "Arpdau by country or region by day",
  ARPPUByCountryOrRegionByDay: "Arppu by country or region by day",
  ConversionRateGroupedByCountryOrPgion: "Conversion rates are grouped by country or region, paying users per day",
  ToTalPayUsersAreGroupedByCountryOrRegion: "otal pay users are grouped by country or region",
  ToTalFeesAreGroupedByCountryOrRegion: "Total pay amount are grouped by country or region",
  FeesAreGroupedByCountryOrRegion: "Pay amount are grouped by country or region",
  IAPStatisticalByPayNumber: "Iaps are calculated on a cumulative basis",
  IAPStatisticalByPayAmount: "Iaps are calculated according to accumulated payment amount",
  DailyPayUsersStatistical: "Daily pay users data statistical according to the amount",
  DistributionOfpaymentAmountOnDayN: "Distribution of payment amount on day n",
  DistributionOfPayersOnDayN: "Distribution of payers on day n",
  AdvertisingRevenueByChannelByDay: "Advertising revenue by channel by day",
  AverageDailyPayment: "Average daily payment amount of active users, total payment/number of daily active users",
  PayGroupedByPlatform: "Pay grouped by platform",
  PayUserArev: "Paid users Average paid amount, total paid/number of paid users",
  DailyADArpdau: "Arpdau of daily AD revenue",
  TimeRangeRevenue: "The cumulative revenue generated during the selected time period of the advertisement",
  ConversionPaid: "Conversion rate, paid users/daily live",
  PayCountTotal: "Total pay user",
  RetainGroupByPlatform:
    "Retention is grouped by platform. Registered users are those who register on the same day and meet the filtering criteria (those who meet the criteria later are not counted).",
  RetainGroupByCountryOrRegion:
    "Retention is grouped by country or region. Registered users are those who register on the same day and meet the filtering conditions on the same day (those who meet the conditions later are not counted).",
  RetainGroupByDay:
    "Retention is grouped by day. Registered users are the users who register on the same day and meet the filtering conditions on the same day (those who meet the conditions later are not counted).",
  DAUVersion: "DAU-version",
  DAUConversionType: "DAU-Conversion type",
  DAUDistribution: "DAU-Distribution",
  DAUCountryOrRegion: "DAU-Country Or Region",
  DNUCountryOrRegion: "DNU-Country Or Region",
  DAUChannel: "DAU-Channel",
  DNUChannel: "DNU-Channel",
  DauDealData:
    "Dau by version by day, excluding the data that the total number of people in a particular version in the selected time range only accounts for one thousand of the total number of people in all versions",
  DNUGroupedByType: "DNU grouped by type",
  DailyActiveUserGroupedByDay_n: "Daily active user grouped by Day_n",
  DailyActiveUserGroupedByUA: "Daily active user grouped by UA material group",
  DailyRegistUserGroupedByUA: "Daily registered users are grouped by UA material group",
  DailyActiveUserGroupedByCountryorRegion: "Daily active users grouped by country or region",
  DailyRegistUserGroupedByCountryorRegion: "Daily registered users grouped by country or region",
  DailyActiveGroupByChannel: "Daily active user group by channel",
  DailyRegisteredUsersGroupByChannel: "Daily registered users grouped by channel",
  PayUsersNum: "Pay Users Num",
  LuaRErrorLogTotal: "Total Lua error logs",
  LuaRErrorLogDevicesevenue: "Today Lua error log devices",
  RErrorLogDevicesevenue: "Today error log devices",
  RErrorLogTotal: "Total error logs",
  TodayRevenue: "Today Revenue",
  LowVersion: "LowVersion",
  HeightVersion: "HeightVersion",
  NewUsersRevenue: "Revenue from new users",
  AddPayingUsers: "Add paying subscribers",
  NewUsers: "Add users",
  HaveNoModify: "The current two versions are not modified",
  HightThenLower: "The high version needs to be higher than the low version",
  FileCompareExplain: "Click the title to switch states,left is old,right is new",
  RemarkNeed: "Please entry remark and continue submit",
  ChooseLineOrCreateFile: "Please select any line or create a new file and continue",
  FileNameNeed: "Please entry file name",
  AddTag: "Add Tag",
  FileNmae: "File Nmae",
  AddFile: "Add File",
  OpenCompare: "Open Compare",
  CloseCompare: "Close Compare",
  FillRemark: "Fill in necessary remarks",
  Submit: "submit",
  ConfigurationFileName: "Configuration File Name",
  File: "File",
  SelectTag: "SelectTag",
  All: "all",
  PleaseChoose: "Please Choose",
  LatestFile: "latest file",
  TagList: "tag-list",
  CreateTag: "Create Tag",
  CreateFile: "Create File",
  ReviewPicture: "Review Picture",
  ClickToUpload: "Click to upload",
  PictureDrag: "drag file to here,or ",
  AlbumName: "Album Name",
  AlbumNameNeed: "Album name cannot be empty",
  CreateAlbum: "CreateAlbum",
  ErrPageTip: "Error:Can not find page",
  PageName: "Page Name",
  AddGroup: "Add Group",
  PageGroup: "Page Group",
  GroupName: "Group Name",
  ModifyGroup: "Modify Group",
  YTypeNeed: "Please choose Y shaft type",
  XTypeNeed: "Please choose x shaft type",
  DataSourceNeed: "Please choose data source",
  ChartTypeNeed: "Please choose chart type",
  ChartNameNeed: "Please entry chart name",
  Time: "time",
  Num: "number",
  TableDataType: "table-DataType",
  TableBasis: "table-Basis",
  HistogramFunnel: "histogram-Funnel",
  HistogramTransverse: "histogram-Transverse",
  HistogramLongitudinal: "histogram-ongitudinal",
  LineChartAreaStack: "lineChart-AreaStack",
  LineChartBasisSmoothStack: "lineChart-BasisSmoothStack",
  LineChartBasisStack: "lineChart-BasisStack",
  LineChartArea: "lineChart-Area",
  LineChartBasis: "lineChart-Basis",
  LineChartBasisSmooth: "lineChart-BasisSmooth",
  EditData: "Edit Data",
  AddData: "Add Data",
  CustomData: "Custom Data",
  SystemData: "System Data",
  CurrencyNeed: "Need Currency",
  CreateProduct: "Create Product",
  EditProduct: "Edit Product",
  ProductIdNeed: "Need Product Id",
  StoreNeed: "Need Store",
  ProductTypeNeed: "Need ProductType",
  PriceNeed: "Need Price",
  NameNeed: "Need Name",
  No: "no",
  Yes: "yes",
  StatusNeed: "Need Status",
  ConsumableNeed: "Need Consumable",
  SubscriptionNeed: "Need Subscription",
  CurrencyAmountNeed: "Need Currency Amount",
  SingleStoreDetails: "Single Store Details",
  DeleteAll: "deleteAll",
  AreYouSureDeleteAll: "Are you sure delete all",
  Dimension: "dimension",
  SecondDimension: "Second Dimension",
  TableDAU: "Daily Report DAU",
  TableDNU: "Daily Report DNU",
  FormDocument: "Form Document",
  DataView: "dataView",
  Refresh: "refresh",
  Contrast: "contrast",
  ReverseChoice: "reverseChoice",
  TableData: "Table Data",
  CurrentClassConditionHasBeenCreated: "The current class condition has been created",
  CurrentConditionHasBeenCreated: "The current condition has been created",
  CustomConditionManagement: "Custom condition management",
  To: "to",
  RegistTime: "Regist Time",
  CreateAtLeastOneCondition: "Create at least one condition",
  GetFilterDataFail: "Get filter data fail",
  EnterAlias: "Please enter an alias",
  SaveSearchCriteria: "Save search criteria",
  DataFile: "dataFile",
  Download: "download",
  FunnelPlot: "Single columnar funnel plot",
  Close: "close",
  NoIntroduce: "No introduce current",
  Introduce: "introduce",
  ChooseAll: "chooseAll",
  ChooseRegion: "Select a country or region",
  RegionalFigure: "Area of broken line",
  SmoothFigure: "Smooth Line",
  Histogram: "histogram",
  BasisEcharts: "Foundation Drawing",
  ChineseAndEnglishPunctuation: "chinese and english punctuation",
  SensitivePrompt: "note: punctuation marks must be consistent with English, can not be mixed",
  Entry: "entry",
  EnterCorrectFormat: "Please enter the correct format",
  Or: "or",
  InputFormat: "Input format",
  SenUnit: " ",
  LatestSensitiveWord: "The latest entry of sensitive words",
  Stored: "store",
  WeightNeed: "The weight value cannot be empty",
  AddRule: "Add Rule",
  AddCondition: "Add Condition",
  RuleManagePrompt:
    "First-order regular sorting is bottom-up. Subrules sort from right to left. If the subrule is heavy, only one will be hit",
  ResetSuccess: "Reset success",
  UnbindSuccess: "Unbind success",
  AddedSuccess: "Added successfully",
  ModifiedLocationSuccess: "Modified location succeeded",
  PushCampaignIdNotExist: "PushCampaignId not exist",
  CreateNewPush: "Create new push",
  Operation: "operation",
  NumberOfSuccessfulPush: "Number of successful push ",
  NumberOfMatches: "Number of matches",
  CopySuccess: "Copy Success",
  DownloadSuccess: "Download Success",
  RemoveCurrent: "Please remove the current file before uploading it",
  EditingDataSucceeded: "eEditing data succeeded",
  PageReplacemenSuccess: "Page replacement success",
  ModifyGridSuccess: "Modify grid success",
  FilterDataRefreshSuccess: "Refresh filter data success",
  ConditionNameNeed: "No condition name is entered",
  DeletePlayerConfirm: "Are you sure to delete the player?",
  ReqFail: "Request Fail",
  NeedLogin: "Need Login",
  TagBinded: "Tag bound",
  SensitiveWordsNeed: "Sensitive words must not be empty",
  ImportedSuccess: "Imported successfully number:",
  EnterEmailAccount: "Please enter your email account!",
  EmailExists: "Email already exists!",
  SelectApp: "Please select at least one app!",
  EmailSendFail: "Email send failed",
  UserIdNeed: "UserId must not be empty",
  RoleCanNotModify: "Administrator and super administrator cannot modify!",
  RoleCanNotDelete: "Administrator and super administrator cannot be deleted！",
  UserCanNotDelete: "Users exist under the role and cannot be deleted!",
  PicShouldBeSmall: "Pictures should be less than 500K",
  SendFail: "Failed to send message",
  SendSuccess: "Sent successfully",
  MessageVerificationFailed: "Message verification failed",
  TransformContentEmpty: "The content of the translation cannot be empty",
  TranslateSuccess: "Translated successfully",
  TranslateFail: "Translation failed, please try again later!",
  BindSuccess: "Binding success",
  BindFail: "Binding failed",
  TrueTagId: "Please enter the correct tagId",
  HighThanLow: "Higher version than lower version",
  ContentIdNeed: "ContentId cannot be empty",
  TagNameNeed: "Tag name cannot be empty",
  TagNameAlreadyExists: "Tag name already exists",
  UserDoesNotExist: "User does not exist",
  EsVersionFail: "Es failed to query minor version number",
  AlbumNameExist: "Album name already exists",
  UploadSuccess: "Upload successful",
  AppIdCannotBeEmpty: "AppId cannot be empty",
  MessageNotBeEmpty: "Message Content must not be empty",
  CreateSuccess: "Created successfully",
  CreateFailed: "Create failed",
  FileNotEmpty: "File name must not be empty",
  JsonEmpty: "Json data is empty",
  GetSuccess: "Get Success",
  EditSortSucceeded: "Edit Sort Succeeded",
  ParameterError: "Parameter Error",
  TeamIdNotExist: "TeamId Not Exist",
  NoSuchMemberExists: "NoSuch Member Exists",
  OperationSuccessful: "Operation Successful",
  OperationFailed: "Operation Failed",
  SaveSuccess: "save Success",
  SaveError: "save failed",
  DeleteError: "delete Failed",
  ParameterErrorUpload: "Parameter error, please refresh before uploading ",
  FileBig: "The file is too big",
  UploadFailed: "Upload failed",
  UnfileStatus: "Please unfile the parent rule first",
  ModifiedError: "Modified Error",
  NoPermission: "No Permission",
  EsInterfaceError: "Es interface failed to get data",
  ModifiedSuccess: "Modified Success",
  Pie: "pieChart",
  LineChart: "lineChart",
  PieBasis: "Pie Basis",
  SureRefreshPassword: "Are you sure to reset your password ?",
  DeleteAccount: "Are you sure to delete the account?",
  Percentage: "percentage",
  Number: "number",
  ShowType: "showType",
  YDisplay: "Y Display",
  XDisplay: "X Display",
  FormParameterCase: "The creation format is label-key. For example,maxLevel-max_level",
  FormParameter: "Form Parameter",
  BelongPage: "belong Page",
  FormName: "form Name",
  UnfilledTip: "You have some data left unfilled",
  RequestSuccess: "Request Success",
  DataSourceUrl: "data Source Url",
  DataSourceName: "data Source Name",
  Data: "data",
  Source: "source",
  DataSource: "data source",
  DataType: "data type",
  OsDataDownName: "Purchase volume daily (overseas) after screening data",
  GetMenuError: "Failed to get directory and appId. Please try again",
  CopyPassword: "Copy Password",
  UnzipPassword: "unzip password",
  DownloadPassword: "The password for decompressing the downloaded package",
  Catalog: "Catalog",
  PropertyValueManagement: "Property Value Management",
  PropertyManagement: "PropertyManagement",
  MaterialClassification: "Material Classification",
  MaterialManagement: "Material Management",
  SetUp: "Setting",
  CustomReport: "Custom Report",
  Cohort: "Cohort",
  DataRefreshError: "Data refresh error, please try again later",
  DeleteSuccess: "Delete Success",
  Tips: "tips",
  AreYouSureDelete: "Are you sure delete?",
  AtLeastOnConditions: "Requires at least one set of conditions",
  GetNoData: "can't get data under current conditions",
  GroupNoData: "Current Group No Data",
  SearchSuccess: "Search Success",
  EchartsType: "echarts Type",
  GroupChooseAll: "Group Choose All",
  GroupSelection: "Group Selectioin",
  SearchAll: "Search All",
  AddContrast: "Add Contrast",
  Chinese: "Chinese",
  Day: "day",
  RetentionAverage: "Retention Average",
  English: "English",
  Range: "range",
  PackageConversionRatePercentile: "package conversion rate percentile",
  PackageConversionRate: "package conversion rate",
  PackagesPurchasedPerDayNum: "Number of packages purchased per day",
  FilterConditions: "filter Conditions",
  Background: "background",
  UpToNow: "To Today",
  "7Day": "7 Days",
  "14Day": "14 Days",
  "30Day": "30 Days",
  "90Day": "90 Days",
  "180Day": "180 Days",
  OneDay: "Day",
  Table: "Table",
  Echarts: "Chart",
  Hours: "hours",
  Minutes: "minutes",
  DNUReport: "DNU Report",
  DAUReport: "DAU Report",
  Save: "save",
  Account: "account",
  EnterTag: "Please enter a custom tag",
  QuickSearch: "QuickSearch",
  RefreshFilterData: "Refresh filter data",
  ClearESSearch: "Clear ES Search",
  FileCompare: "File Compare",
  Album: "Album",
  GoodsDetails: "Goods Details",
  DetailData: "Detail Data",
  ErrorPage: "Error Page",
  TagManage: "Tags Manage",
  Analysis: "Analysis",
  ActiveUser: "New & Active",
  Revenue: "Revenue",
  UAData: "UA Data",
  UADataAD: "UA Data(AD)",
  UADataOS: "UA Data(OS)",
  UADataMerge: "UA Data(merge)",
  DailyReport: "Daily Report",
  View: "View",
  IAPRevenue: "IAP Revenue",
  TimeYesterday: "Yesterday Realtime",
  YesterdayTotal: "Yesterday Total",
  ImportData: "Import Data",
  Upload: "Upload",
  UploadAFile: "Upload a file",
  UploadingOldFiles: "Uploading old files",
  EnterPageName: "Please enter the page name",
  Cancel: "Cancel",
  Comfirm: "Comfirm",
  ExportAllGroup: "Export All Groups",
  InputAllGroup: "Input all groups",
  DataSourceManage: "Data Source Manage",
  AddPage: "Add Page",
  AddTable: "Add Table",
  Export: "Export",
  DataName: "Data Name",
  CreateDataSource: "Create Data Source",
  ExportFilteredData: "Export Filtered Data",
  PayCountByUsers: "Counts By Users",
  RevenueByUsers: "Revenue By Users",
  PayCountByAdUsers: "Counts By Advertise Users",
  RevenueByAdUsers: "Revenue By Advertise Users",
  AccumulatedIAPrevenue: "Accumulated IAP revenue",
  AccumulatedPayCount: "Accumulated Pay Count",
  IAPConversion: "IAP Conversion",
  ARPPU: "ARPPU",
  IAPARPDAU: "ARPDAU",
  ValueExplain1: "All values are the top-left indicator of the score",
  ValueExplain2:
    "The calculation method is (the sum of the daily numerator/the sum of the daily denominator), and does not involve de-weighting",
  D2RetentionByCountry: "D2Retention By Country",
  D7RetentionByCountry: "Day7 Retention By Country",
  D30RetentionByCountry: "Day30 Retention By Country",
  D2RetentionByPlatform: "D2Retention By Platform",
  D7RetentionByPlatform: "Day7 Retention By Platform",
  D30RetentionByPlatform: "Day30 Retention By Platform",
  ClickShowEcharts: "Click the row to display the echart",
  ExportData: "Export",
  UniqueDAU: "Unique DAU",
  CumulativeDNU: "Cumulative DNU",
  AverageDAU: "Average DAU",
  ThisMonth: "This Month",
  LastMonth: "Last Month",
  Last14Days: "Last 14 days",
  Last30Days: "Last 30 Days",
  Last90Days: "Last 90 Days",
  Last21Days: "Last 21 Days",
  Last60Days: "Last 60 Days",
  LastWeek: "Last 7 Days",
  Yesterday: "Yesterday",
  StartTime: "Start Time",
  EndTime: "End Time",
  AllRegisteredUsers: "All Registered Users",
  Delete: "Delete",
  TagsManage: "Tags Manage",
  Sort: "Sort",
  LevelTable: "Level Table",
  GiftData: "Promotional",
  PayCountByValue: "Pay Counts By Value",
  PayCountByInstall: "Pay Counts By Install",
  TimeZone: "TimeZone",
  Date: "Date",
  QuickReplyManage: "Quick Reply",
  Document: "Document",
  ChangePassword: "Change Password",
  Logout: "Log out",
  Login: "Login",
  SystemManage: "App Info",
  AccountManage: "Accounts",
  RoleManage: "Role",
  Logs: "Change logs",
  RealtimeData: "Realtime",
  See: "View",
  Edit: "Edit",
  Calculate: "Calculate",
  CalculateEchart: "CalculateEchart",
  RuleManage: "Rule Manage",
  Lists: "Lists",
  Add: "Add",
  TeamManage: "Team Manage",
  PushManage: "Push Campaigns",
  UserQuery: "User Manage",
  UserQueryByAccounts: "By Accounts",
  UserQueryByDevices: "By Devices",
  PicturesManage: "Pictures",
  UserMessages: "User Messages",
  CustomerServiceSystem: "Customer Service",
  CustomerManage: "Staff Manage",
  SensitiveWord: "Sensitive Word",
  TeamRank: "Team Rank",
  RetentionLine: "Retention",
  AdRevenue: "Ad Revenue",
  TotalAdRevenue: "Total Ad Revenue",
  GiftPurchasesCount: "Gift Purchases Count",
  Today: "Today",
  ActiveUsers: "Active Users",
  Conversion: "Conversion",
  Retention: "Retention",
  Platform: "Platform",
  Version: "Version",
  AssetVersion: "AssetVersion",
  Version2: "Init_Version",
  AssetVersion2: "Init_AssetVersion",
  Channel: "Install source",
  Tag: "Tags",
  Include: "contains",
  CustomerId: "CustomerId",
  Select: "Select",
  Android: "Android",
  IOS: "iOS",
  SaveFilter: "Save Filter",
  SavedFilters: "Saved Filters",
  And: "&",
  ClearSearch: "Clear Filter",
  Back: "Back",
  Update: "Update",
  Create: "Create",
  Rule: "Rule",
  Attribute: "Attribute",
  CumulativePayCount: "Cumulative Paid Users",
  ConfigManage: "Config Manage",
  D2Retention: "Day 2 Retention",
  D7Retention: "Day 7 Retention",
  D30Retention: "Day 30 Retention"
};
export const lanData = {
  cn: cn,
  en: en
};
