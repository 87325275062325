import * as Vuex from "vuex";

interface state {
  browserName: string;
  minScreen: string;
  appId: string;
  symbol: string;
  download: number;
  esedit: number;
  apps: Array<Object>;
}
const vuexState: state = {
  browserName: "",
  minScreen: "",
  appId: "",
  symbol: "",
  download: null,
  esedit: null,
  apps: null
};
export default Vuex.createStore({
  state: vuexState,
  getters: {
    /**浏览器名 */
    getBrowserName(state) {
      return state.browserName;
    },
    /**是否是手机*/
    getIsphone(state) {
      return state.minScreen;
    },
    /**项目appid */
    getAppId(state): string {
      return state.appId;
    },
    getApps(state): Array<Object> {
      return state.apps;
    },
    getSymbol(state): string {
      return state.symbol;
    },
    getDownload(state): number {
      return state.download;
    },
    getEsedit(state): number {
      return state.esedit;
    }
  },
  mutations: {
    commitBrowserName(state, data) {
      state.browserName = data;
    },
    commitMinScreen(state, data) {
      state.minScreen = data;
    },
    commitAppId(state, data) {
      state.appId = data;
    },
    commitApps(state, data) {
      state.apps = data;
    },
    commitSymbol(state, data) {
      state.symbol = data;
    },
    commitDownload(state, data) {
      state.download = +data;
    },
    commitEsedit(state, data) {
      state.esedit = +data;
    }
  },
  actions: {
    setBrowserName({ commit }, data) {
      commit("commitBrowserName", data);
    },
    setMinScreen({ commit }, data) {
      commit("commitMinScreen", data);
    },
    setAppId({ commit }, data) {
      commit("commitAppId", data);
    },
    setApps({ commit }, data) {
      commit("commitApps", data);
    },
    setSymbol({ commit }, data) {
      commit("commitSymbol", data);
    },
    setDownload({ commit }, data) {
      commit("commitDownload", data);
    },
    setEsedit({ commit }, data) {
      commit("commitEsedit", data);
    }
  }
});
