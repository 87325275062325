import type { IEchartToolData } from "../projectTypeDefine";

export const obj = {
  trigger: "axis",
  axisPointer: {
    type: "line" // 默认为直线，可选为：'line' | 'shadow'
  },
  padding: 10,
  backgroundColor: "#fff",
  borderColor: "#cacaca",
  borderWidth: 1,
  extraCssText: "max-height:calc( 100vh - 540px );min-height:300px;overflow:auto",
  enterable: true,
  textStyle: {
    color: "#000"
  },
  position: function (
    pos: [number, number],
    params: Array<any>,
    dom: HTMLElement,
    rect: any,
    size: { contentSize: [number, number]; viewSize: [number, number] }
  ) {
    console.log(pos, params, dom, rect, size, "------>");
    dom.addEventListener(
      "mouseleave",
      function (event) {
        const dom = event.target as HTMLElement;
        dom.style.display = "none";
      },
      false
    );

    // 鼠标在左侧时 tooltip 显示到右侧，鼠标在右侧时 tooltip 显示到左侧。
    const dw = size.contentSize[0];
    const dh = size.contentSize[1];
    const vw = size.viewSize[0];
    const px = pos[0];
    const mx = Math.max(vw - dw, 0); //echarts盒子比tooltip盒子宽多的值
    const adaptX = (px / vw) * mx;
    const obj = {
      top: -dh + 40,
      left: adaptX === 0 ? vw / 2 - dw / 2 : adaptX
    };
    console.log("======>", obj);
    return obj;
  },
  formatter: function (data: Array<IEchartToolData>) {
    let tmp_tooltip = "";
    let name = "";
    let total = 0;
    data.forEach(function (element, index) {
      if (element.value === "-") {
        return;
      }
      const index1 = data.findIndex((item) => {
        return item.seriesName === element.seriesName;
      });
      if (index !== index1 && data[index].value === data[index1].value) {
        return;
      }
      name = element.name;
      total += !isNaN(+element.value) ? +element.value : 0;
      const tmp_value = element.value === undefined ? "-" : element.value;

      tmp_tooltip +=
        `<div style="display: flex;align-items: center;line-height:20px">
            <p style="background-color: ` +
        element.color +
        `;width: 11px;height: 11px;border-radius: 50%;margin-right: 7px;;"></p><b style="font-size: 13px;">` +
        element.seriesName +
        `</b>:&nbsp;&nbsp;<b style="font-size: 13px;">` +
        (!isNaN(+tmp_value) ? Math.floor(+tmp_value * 10000) / 10000 : tmp_value) +
        `</b><br/>
            </div>`;
    });
    tmp_tooltip =
      `<b style="font-size: 15px;">total:&nbsp;&nbsp;</b>` +
      (!isNaN(+total) ? Math.floor(+total * 10000) / 10000 : total) +
      "<br/>" +
      tmp_tooltip;
    tmp_tooltip = name + "<br/>" + tmp_tooltip;
    return tmp_tooltip;
  }
};
