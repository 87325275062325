import { lanData } from "./language";

import ElementPlus from "element-plus";
// import zhCn from "element-plus/dist/locale/zh-cn.mjs";
// import en from "element-plus/dist/locale/en.mjs";
import { langTypeEmum } from "../projectTypeDefine";

export const global = {
  data: lanData,
  language: {},
  setLan(type: langTypeEmum) {
    this.language = lanData[type];
  },
  reqStatus: true
};
