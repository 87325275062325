import * as Vue from "vue";
import App from "@/App.vue";
import router from "./router";
import store from "./store";
import { global } from "@/util/lan/global";
import * as fun from "@/util/common";
import { lanShow } from "@/util/pluginFunction/index";
import "font-awesome/css/font-awesome.min.css";
import "default-passive-events";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import { reqEditApi, reqApi } from "./util/http/interfaceObj";
import { ClickOutside } from "element-plus";
import VueGridLayout from "vue-grid-layout";
import echartsCom from "@/components/echart-com1.vue";

// main.js
export const app = Vue.createApp(App);
for (const k in fun) {
  app.config.globalProperties[k] = fun[k];
}
app.config.globalProperties.GLOBAL = global;
app.config.globalProperties.$_filterData = { data: [], reg: null };
app.config.globalProperties.$_interType = { reqEditApi, reqApi };

app.config.globalProperties.lanShow = lanShow(app.config.globalProperties);
app.use(VueGridLayout);
app.directive("clickOutside", ClickOutside);

app.use(ElementPlus, { size: "small", message: { max: 1 } });
app.use(store);

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}
app.component("echartsCom", echartsCom);

app.use(router);
app.mount("#app");
