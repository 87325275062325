import type { IEchartSeriesItem, IEchartToolData } from "../projectTypeDefine";

/**针对echarts 平均值处理*/
export function arevConfig(series: Array<IEchartSeriesItem>) {
  const obj: { string?: boolean } = {};
  let status = false, //是否有平均值
    arevIndex: number = null, //平均值index
    shortIndex = -1; //除平均值外最短长度
  series.forEach((item, index) => {
    if (item.name === "平均值") {
      obj[item.name] = true;
      arevIndex = index;
      status = true;
    } else {
      if (shortIndex === -1) {
        shortIndex = item.data.length;
      } else {
        shortIndex = Math.min(shortIndex, (item.data && item.data.length) || 0);
      }
      obj[item.name] = false;
    }
  });
  if (status) {
    //该页面需要针对平均值做处理
    const arr = [
      {
        name: series[arevIndex].name,
        data: series[arevIndex].data.map((item, index) => {
          if (index >= shortIndex) {
            return "-";
          } else {
            return item;
          }
        })
      },
      {
        lineStyle: {
          type: "dashed"
        },
        name: series[arevIndex].name,
        data: series[arevIndex].data.map((item, index) => {
          if (index < shortIndex - 1) {
            return "-";
          } else {
            return item;
          }
        })
      }
    ];
    series.splice(arevIndex, 1, ...arr);
  }
  if (!obj["平均值"]) {
    for (const k in obj) {
      obj[k] = true;
    }
  } //没有平均值的话全部取正
  return { series, obj };
}
export function perTool(data: Array<IEchartToolData>) {
  let tmp_tooltip = "";
  let name = "";
  data.forEach(function (element) {
    name = element.name;
    const tmp_value = element.value;
    tmp_tooltip +=
      `<div style="display: flex;align-items: center;">
            <p style="background-color: ` +
      element.color +
      `;width: 11px;height: 11px;border-radius: 50%;margin-right: 7px;;"></p><b style="font-size: 13px;">` +
      element.seriesName +
      `</b>:&nbsp;&nbsp;<b style="font-size: 13px;">` +
      ((Number(tmp_value) * 100).toFixed(2) + "%") +
      `</b><br/>
            </div>`;
  });
  tmp_tooltip = name + "<br/>" + tmp_tooltip;
  return tmp_tooltip;
}

/**返回tooltip悬浮框样式 */
export function perTool3(data: Array<IEchartToolData>) {
  let tmp_tooltip = "";
  let name = "";
  data.forEach(function (element) {
    name = element.name;
    const tmp_value = element.value;
    tmp_tooltip +=
      `<div style="display: flex;align-items: center;">
            <p style="background-color: ` +
      element.color +
      `;width: 11px;height: 11px;border-radius: 50%;margin-right: 7px;;"></p><b style="font-size: 13px;">` +
      element.seriesName +
      `</b>:&nbsp;&nbsp;<b style="font-size: 13px;">` +
      (Math.round(Number(tmp_value) * 100000) / 1000 + "%") +
      `</b><br/>
            </div>`;
  });
  tmp_tooltip = name + "<br/>" + tmp_tooltip;
  return tmp_tooltip;
}
/**大数据echarts 悬浮框显示 */
export function positionContrast(
  pos: [number, number],
  params: Array<any>,
  dom: HTMLElement,
  rect: any,
  size: { contentSize: [number, number]; viewSize: [number, number] }
) {
  console.log(params, dom, rect, size, "------->");
  dom.addEventListener(
    "mouseleave",
    function (event) {
      const dom = event.target as HTMLElement;
      dom.style.display = "none";
    },
    false
  );

  // 鼠标在左侧时 tooltip 显示到右侧，鼠标在右侧时 tooltip 显示到左侧。
  const dw = size.contentSize[0];
  const dh = size.contentSize[1];
  const vw = size.viewSize[0];
  const px = pos[0];
  const mx = Math.max(vw - dw, 0); //echarts盒子比tooltip盒子宽多的值
  const adaptX = (px / vw) * mx;
  const obj = {
    top: dh - 160,
    left: adaptX === 0 ? vw / 2 - dw / 2 : adaptX
  };
  return obj;
}
