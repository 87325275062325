<template>
  <div class="search-group">
    <div class="pop-container">
      <el-popover popper-class="pop" placement="bottom" :visible="popStatus" @hide="popHide" @show="popShow">
        <div class="condition-container" ref="popContent" @click.stop="">
          <div class="save-conditions">
            <div class="title">
              <span>{{ lanShow("SavedFilters") }}</span>
            </div>
            <div class="saved-box">
              <div class="saved-item" v-for="(item, index) in savedFilterArr" :key="index" @click="useItem(item)">
                {{ item.name }}
                <span class="close-icon cursor" @click.stop="deleteSaved(item)">&#xe605;</span>
              </div>
            </div>
          </div>
          <div class="new-conditions" v-if="step === 1">
            <div class="title">
              <input
                @input="selectChange(1)"
                type="text"
                class="search-input"
                v-model="searchText"
                :placeholder="lanShow('QuickSearch')" />
            </div>
            <div class="conditions-container">
              <div v-for="(item, index) in filterData" :key="index">
                <div class="con-step1 con-label" @click="step1Click(item)" v-show="item.show">
                  {{ item.name }}
                </div>
              </div>
              <div
                class="con-step1 con-label"
                v-if="currentPage !== 'realData' && currentPage !== 'service'"
                @click="regStepClick"
                v-show="'注册时间'.indexOf(searchText) >= 0">
                {{ lanShow("RegistTime") }}
              </div>
            </div>
          </div>
          <div class="new-conditions" v-if="step === 2">
            <div class="title">
              <span>{{ lanShow("Select") }}</span>
            </div>
            <div class="conditions-container">
              <div class="con-label" v-for="(item, index) in includeData" :key="index" @click="step2Click(item)">
                {{ item.name }}
              </div>
            </div>
          </div>
          <div class="new-conditions" v-if="step === 3">
            <div class="title">
              <span>{{ popData.name }}</span>
              <input
                @input="selectChange(3)"
                type="text"
                class="search-input"
                v-model="searchText"
                :placeholder="lanShow('QuickSearch')" />
              <el-checkbox
                class="choose-all"
                :indeterminate="someoneChoose"
                v-model="allChoose"
                @change="chooseCurrentAll"></el-checkbox>
              <el-button circle @click="showAddDialog">
                <el-icon><Plus /></el-icon>
              </el-button>
            </div>
            <div class="conditions-container">
              <div class="option-container">
                <div class="con-item" v-for="(item, index) in popData.children" :key="index">
                  <div v-if="item.children">
                    <div class="con-title" v-show="item.show" @click="toggleChoose(item)">
                      {{ item.name }}
                    </div>
                    <div
                      class="con-label"
                      v-for="(child, childIndex) in item.children"
                      :key="childIndex"
                      @click="toggleChooseItem(child, index, item)"
                      v-show="child.show">
                      <span>
                        {{ child.name }}
                      </span>
                      <el-icon class="icon" v-show="child.status"><Check /></el-icon>
                    </div>
                  </div>
                  <div v-else>
                    <div class="con-label" v-show="item.show" @click="toggleChooseItem(item, index)">
                      <span>
                        {{ item.name }}
                      </span>
                      <el-icon class="icon" v-show="item.status"><Check /></el-icon>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="new-conditions" v-if="step === 'regStep'">
            <div class="title">
              <span> {{ lanShow("AllRegisteredUsers") }} </span>
            </div>
            <div class="conditions-container">
              <time-com
                class="regTime"
                :popperClass="'regTimeCom'"
                @timeChange="registeredTimeGet"
                :init="false"></time-com>
            </div>
          </div>
        </div>
        <template v-slot:reference>
          <div class="popBtn" @click="getItemData" v-clickOutside="hidePopSelf">
            <div v-if="pageData.name" class="condition-show">
              <div v-if="regTime.length > 0">
                <span style="color: rgb(5, 166, 226)">{{ lanShow("RegistTime") }}</span
                >{{ timeSystemFormat(regTime[0]) + lanShow("To") + timeSystemFormat(regTime[1]) }}
              </div>
              <div v-else>
                <span style="color: rgb(5, 166, 226)">{{ pageData.name + " " }}</span>
                <span style="color: rgb(255, 163, 31)">{{ pageData.scope.name + " " }}</span>
                {{ showText() }}
              </div>
            </div>
            <div v-else class="addBtn"></div>
          </div>
        </template>
      </el-popover>
    </div>

    <div class="close-btn cursor" v-show="pageData.name !== ''" @click="closeCurrent">
      <el-button type="info" circle>
        <el-icon><Close /></el-icon>
      </el-button>
    </div>
  </div>
  <el-dialog
    :title="lanShow('CustomConditionManagement')"
    :width="minScreen ? '300px' : '400px'"
    v-model="addDiaVisible">
    <div class="input-container">
      <span v-show="currentData.length !== 0">{{ lanShow("SavedFilters") }}：</span>
      <el-button
        v-for="(btn, bIndex) in currentData"
        type="danger"
        style="margin: 2px"
        size="small"
        :key="bIndex"
        @click="deleteSelfFilter(btn, bIndex)"
        >{{ btn.name }}</el-button
      >
      <br />
      <el-form inline @submit.prevent size="small">
        <el-form-item>
          <el-input :placeholder="lanShow('EnterTag')" v-model="saveContent"> </el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click.stop="addNewfilter">{{ lanShow("Save") }}</el-button>
        </el-form-item>
      </el-form>
    </div>
  </el-dialog>
</template>

<script>
import { myboPost } from "@/util/http";
import { mapState } from "vuex";
import timeCom from "./time-com.vue";

const mp = myboPost();

export default {
  emits: ["itemChange", "registeredTimeGet"],
  data() {
    return {
      popStatus: false,
      pageData: {
        valueArr: [],
        scope: {
          value: "",
          name: ""
        },
        name: "",
        value: ""
      },
      //自定义条件悬浮框显隐
      addDiaVisible: false,
      //自定义标签的数据
      currentData: [],
      //pop框中自定义保存数据value
      saveContent: "",
      //pop框中搜索框内容
      searchText: "",
      //已保存到服务器的搜索条件
      savedFilterArr: [],
      searchArr: [
        //过滤器保存条件数组
        {
          valueArr: [],
          scope: { value: "", name: "" },
          name: "",
          value: ""
        }
      ],
      //步骤
      step: 1,
      //第二步数据
      includeData: [],
      //第三步数据
      popData: {},
      //全部数据
      filterData: [],
      //添加还是修改
      operation: "add",
      //注册时间
      regTime: [],
      currentPage: "",
      popHiding: false,
      someoneChoose: false,
      allChoose: false
    };
  },
  components: {
    timeCom
  },
  props: ["comData", "comIndex", "allData", "type"],
  computed: mapState({
    minScreen: "minScreen"
  }),
  mounted() {
    let { timeArr, ...obj } = this.comData;
    if (timeArr) {
      this.regTime = timeArr;
    }
    this.pageData = obj;
  },
  methods: {
    hidePopSelf(event) {
      //  elementplus elpopver取消弹窗方法是失焦 重写弹出和关闭方法
      let fun = this.contains();
      if (fun(this.$refs.popContent, event.target)) {
        return;
      }
      if (this.step === "regStep") {
        //判断是不是注册时间弹窗内点击事件
        let regTimeNode = document.querySelector(".regTimeCom");
        let regTimeShow = regTimeNode.style.display === "none" ? false : true;
        if (regTimeShow && fun(regTimeNode, event.target)) {
          return;
        }
      }

      if (this.addDiaVisible) {
        return;
      }
      this.popStatus = false;
    },
    showAddDialog() {
      this.addDiaVisible = true;
    },
    chooseCurrentAll() {
      this.someoneChoose = false;
      if (this.allChoose) {
        this.chooseAllFilter = this.popData.children.forEach((item) => {
          if (item.children) {
            item.children.forEach((child) => {
              if (child.show) {
                child.status = true;
              }
            });
          } else {
            if (item.show) {
              item.status = true;
            }
          }
        });
      } else {
        this.chooseAllFilter = this.popData.children.forEach((item) => {
          if (item.children) {
            item.children.forEach((child) => {
              if (child.show) {
                child.status = false;
              }
            });
          } else {
            if (item.show) {
              item.status = false;
            }
          }
        });
      }

      let arr = [];
      this.currentData.forEach((item) => {
        arr.push(item);
      }); //currentData是自定义标签
      this.popData.children &&
        this.popData.children.forEach((item) => {
          //过滤数据可能具有children 需要对子级处理
          if (item.children) {
            let obj = {
              name: item.name,
              value: item.value,
              children: []
            };
            item.children.forEach((child) => {
              if (child.status) {
                obj.children.push({ name: child.name, value: child.value });
              }
            });
            if (obj.children.length !== 0) {
              arr.push(obj);
            }
          } else {
            if (item.status) {
              arr.push({ name: item.name, value: item.value });
            }
          }
        }); //通过item.status判断选中数据
      this.pageData.valueArr = arr;
    },
    regStepClick() {
      // 注册时间单独列出
      let index = this.allData.findIndex((item) => {
        return item.name === "注册时间";
      });
      if (index >= 0) {
        this.$message.warning(this.lanShow("CurrentConditionHasBeenCreated"));
        return;
      }
      this.step = "regStep";
    },
    registeredTimeGet(val) {
      this.regTime = val;
      this.$emit("registeredTimeGet", val);
    },
    deleteSaved(data) {
      //删除保存数据
      mp("custom/conditionsoperation?appId=mybo_flow_cn", {
        appId: this.$store.getters["getAppId"],
        operation: "delCondition",
        id: data.id,
        email: sessionStorage.getItem("userName") || ""
      })
        .then(() => {
          this.$message.success(this.lanShow("DeleteSuccess"));
          this.getSavedFilter();
        })
        .catch(() => {});
    },
    getSavedFilter() {
      mp("custom/conditionsoperation?appId=mybo_flow_cn", {
        appId: this.$store.getters["getAppId"],
        operation: "getConditionsAll",
        email: sessionStorage.getItem("userName") || ""
      })
        .then((res) => {
          if (res.data.data) {
            this.savedFilterArr = res.data.data;
            sessionStorage.setItem("savedFilterArr", JSON.stringify(res.data.data));
          }
        })
        .catch(() => {});
    },
    getItemData() {
      this.popStatus = !this.popStatus;
      //获取当前处于哪一步 并且显示对应
      setTimeout(() => {
        if (this.popHiding) {
          //处于点击按钮关闭弹窗中 需要防止后面的逻辑
          return;
        }
        if (this.filterData.length === 0) {
          let data;
          if (this.type === "service") {
            this.currentPage = "service";
            data = JSON.parse(sessionStorage.getItem("serviceFilterData"));
          } else {
            data = JSON.parse(sessionStorage.getItem("filterData"));
          }
          // if (this.$router.currentRoute.value.name === "realData") {
          //   this.currentPage = "realData";
          //   data = data.filter((item) => {
          //     return item.name === "国家" || item.name === "平台";
          //   });
          // }
          this.filterData = data;
        }

        if (this.savedFilterArr.length === 0 && this.type !== "service") {
          let savedData = sessionStorage.getItem("savedFilterArr");
          if (savedData) {
            this.savedFilterArr = JSON.parse(savedData);
          }
        }
        if (this.pageData.name === "注册时间") {
          this.step = "regStep";
          return;
        }
        //读取当前点击条件数据 只会为第三步(有数据)有数据时操作为update 或者第一步(无数据)操作为add
        if (this.pageData.valueArr.length !== 0) {
          this.searchText = "";
          this.someoneChoose = false;
          this.allChoose = false;
          //valueArr有数据是已经有数据了 第三步
          this.operation = "update";
          this.step = 3;
          let step2 = this.filterData.find((item) => {
            return item.name === this.pageData.name;
          });
          this.popData = step2["children"].find((item) => {
            return item.name === this.pageData.scope.name;
          });
          this.pageData.valueArr.forEach((item) => {
            if (item.self) {
              return; //自己添加的不要处理 提高效率
            }
            if (item.children) {
              let arr = this.popData.children.find((itemInner) => {
                return itemInner.name === item.name;
              });
              arr.children.forEach((item1, index) => {
                item.children.forEach((item2) => {
                  if (item1.name === item2.name) {
                    arr.children[index].status = true;
                  }
                });
              });
              //被选中的改变status显示成选中状态
            } else {
              this.popData.children.forEach((itemInner, index) => {
                if (itemInner.name === item.name) {
                  this.popData.children[index].status = true;
                }
              });
            }
          });
          this.selectChange(3); //在保存对应条件后 需要利用该方法将popData内show置为true以显示
        } else {
          this.operation = "add";
          this.step = 1;
        }
      }, 5);
    },
    popShow() {
      this.currentData = this.pageData.valueArr.filter((item) => item.self);
      this.saveContent = "";
      if (this.filterData.length === 0) {
        let data;
        if (this.type === "service") {
          data = sessionStorage.getItem("serviceFilterData");
        } else {
          data = sessionStorage.getItem("filterData");
        }
        this.filterData = JSON.parse(data);
      }

      if (this.savedFilterArr.length === 0 && this.type !== "service") {
        let savedData = sessionStorage.getItem("savedFilterArr");
        if (savedData) {
          this.savedFilterArr = JSON.parse(savedData);
        }
      }
    },
    popHide() {
      this.triggerType = "click";

      this.popHiding = true;
      setTimeout(() => {
        this.popHiding = false;
      }, 10);
      if (this.regTime.length > 0) {
        // 选择注册时间
        this.$emit("itemChange", {
          type: "regTime",
          index: this.comIndex,
          data: {
            valueArr: this.deepClone(this.regTime),
            scope: {
              value: "",
              name: ""
            },
            name: "注册时间",
            value: ""
          }
        });
        setTimeout(() => {
          this.step = "8";
          // eslint-disable-next-line vue/valid-next-tick
          this.$nextTick(() => {
            this.step = "regStep";
          }, 10); //用来将注册时间选择的值置空
        });
        return;
      }
      //pop隐藏
      let arr = [];
      this.currentData.forEach((item) => {
        arr.push(item);
      }); //currentData是自定义标签
      this.popData.children &&
        this.popData.children.forEach((item) => {
          item.show = true;
          //过滤数据可能具有children 需要对子级处理
          if (item.children) {
            let obj = {
              name: item.name,
              value: item.value,
              children: []
            };
            item.children.forEach((child) => {
              child.show = true;
              if (child.status) {
                obj.children.push({ name: child.name, value: child.value });
              }
            });
            if (obj.children.length !== 0) {
              arr.push(obj);
            }
          } else {
            if (item.status) {
              arr.push({ name: item.name, value: item.value });
            }
          }
        }); //通过item.status判断选中数据
      this.popData = {};
      this.pageData.valueArr = arr;
      if (arr.length !== 0) {
        //当arr有内容时
        if (this.operation === "add") {
          this.$emit("itemChange", {
            type: "add",
            index: this.comIndex,
            data: this.deepClone(this.pageData)
          });
          //添加了一个新数据
        } else {
          this.$emit("itemChange", {
            type: "update",
            index: this.comIndex,
            data: this.deepClone(this.pageData)
          }); //更新了当前数据
        }
      } else {
        if (this.operation === "update") {
          this.$emit("itemChange", {
            type: "delete",
            index: this.comIndex
          }); //没选中任何元素并且是更新操作 则删除当前元素
        } else {
          this.pageData = {
            valueArr: [],
            scope: {
              value: "",
              name: ""
            },
            name: "",
            value: ""
          }; //没选中任何元素并且是添加操作 初始化回最初状态
        }
      }
    },
    closeCurrent() {
      this.$emit("itemChange", {
        type: "delete",
        index: this.comIndex
      }); //关闭当前选项
    },
    useItem(data) {
      this.$emit("itemChange", {
        type: "replace",
        data: this.deepClone(data.filters)
      }); //替换全部
    },
    step1Click(data) {
      let index = this.allData.findIndex((item) => {
        return item.name === data.name;
      });
      if (index >= 0) {
        this.$message.warning(this.lanShow("CurrentConditionHasBeenCreated"));
        return;
      }
      let status = false;
      this.searchArr.forEach((item) => {
        if (item.name === data.name) {
          status = true;
        }
      });
      if (status === true) {
        this.$message.error(this.lanShow("CurrentClassConditionHasBeenCreated"));
        return;
      }
      this.pageData.name = data.name;
      this.pageData.value = data.value;
      this.includeData = data.children;
      this.searchText = "";
      this.someoneChoose = false;
      this.allChoose = false;
      this.step = 2;
      this.$forceUpdate();
    },
    step2Click(data) {
      this.pageData.scope.name = data.name;
      this.pageData.scope.value = data.value;
      if (this.type === "service" && this.pageData.name === "国家") {
        let arr = JSON.parse(sessionStorage.getItem("choosedCountryData"));
        data.children.forEach((item) => {
          if (item.children) {
            item.children.forEach((item1) => {
              if (arr && arr.indexOf(item1.value) >= 0) {
                item1.status = true;
              } else {
                item1.status = false;
              }
              item1.show = true;
            });
          }
          item.show = true;
          item.status = false;
        });
        let choosedArr = [];
        this.$nextTick(() => {
          this.popData.children.forEach((item) => {
            item.show = true;
            //过滤数据可能具有children 需要对子级处理
            if (item.children) {
              let obj = {
                name: item.name,
                value: item.value,
                children: []
              };
              item.children.forEach((child) => {
                child.show = true;
                if (child.status) {
                  obj.children.push({ name: child.name, value: child.value });
                }
              });
              if (obj.children.length !== 0) {
                choosedArr.push(obj);
              }
            } else {
              if (item.status) {
                choosedArr.push({ name: item.name, value: item.value });
              }
            }
          });
        }); //通过item.status判断选中数据
        this.pageData.valueArr = choosedArr;
      } else {
        data.children.forEach((item) => {
          if (item.children) {
            item.children.forEach((item1) => {
              item1.status = false;
              item1.show = true;
            });
          }
          item.show = true;
          item.status = false;
        });
      }

      this.popData = data;
      this.step = 3;

      this.$forceUpdate();
    },
    toggleChoose(data) {
      //步骤三 类点击
      data.children.forEach((item) => {
        item.status = !item.status;
      });
      let index = this.pageData.valueArr.findIndex((item) => {
        return item.value === data.value;
      });
      let obj = {
        name: data.name,
        value: data.value,
        children: []
      };
      data.children.forEach((child) => {
        if (child.status) {
          obj.children.push({ name: child.name, value: child.value });
        }
      });
      if (index >= 0) {
        if (obj.children.length > 0) {
          this.pageData.valueArr.splice(index, 1, obj);
        } else {
          this.pageData.valueArr.splice(index, 1);
        }
      } else {
        this.pageData.valueArr.push(obj);
      }
      this.clickItemCheckBoxChange();
      this.$forceUpdate();
    },
    toggleChooseItem(data, index, par) {
      //步骤三 单个点击
      data.status = !data.status;
      if (par) {
        let index = this.pageData.valueArr.findIndex((item) => {
          return item.value === par.value;
        });
        let obj = {
          name: par.name,
          value: par.value,
          children: []
        };
        par.children.forEach((child) => {
          if (child.status) {
            obj.children.push({ name: child.name, value: child.value });
          }
        });
        if (index >= 0) {
          if (obj.children.length > 0) {
            this.pageData.valueArr.splice(index, 1, obj);
          } else {
            this.pageData.valueArr.splice(index, 1);
          }
        } else {
          this.pageData.valueArr.push(obj);
        }
      } else {
        let index = this.pageData.valueArr.findIndex((item) => {
          return item.value === data.value;
        });
        if (data.status) {
          this.pageData.valueArr.push({ name: data.name, value: data.value });
        } else {
          this.pageData.valueArr.splice(index, 1);
        }
      }
      this.clickItemCheckBoxChange();
    },
    clickItemCheckBoxChange() {
      let haveNoChoose = false;
      let haveChoose = false;
      let showNum = 0;
      this.popData.children.forEach((item) => {
        if (item.children) {
          item.children.forEach((child) => {
            if (child.show === true) {
              showNum++;
              if (child.status === true) {
                haveChoose = true;
              } else {
                haveNoChoose = true;
              }
            }
          });
        } else {
          if (item.show === true) {
            showNum++;
            if (!item.children && item.status === true) {
              haveChoose = true;
            } else {
              haveNoChoose = true;
            }
          }
        }
      });
      if (showNum > 0) {
        if (haveChoose) {
          if (haveNoChoose) {
            this.allChoose = false;
            this.someoneChoose = true;
          } else {
            this.allChoose = true;
            this.someoneChoose = false;
          }
        } else {
          this.allChoose = false;
          this.someoneChoose = false;
        }
      } else {
        this.someoneChoose = false;
        this.allChoose = false;
      }
      if (haveNoChoose) {
        this.allChoose = false;
      }
    },
    selectChange(type) {
      //搜索过滤 有一个输入框可以搜索 通过show控制显隐
      if (type === 1) {
        this.filterData.forEach((item) => {
          if (item.name.toLocaleLowerCase().indexOf(this.searchText.trim().toLocaleLowerCase()) < 0) {
            item.show = false; //过滤显示隐藏
          } else {
            item.show = true;
          }
        });
      } else {
        let haveNoChoose = false;
        let haveChoose = false;
        let showNum = 0;
        this.popData.children.forEach((item) => {
          if (item.name.toLocaleLowerCase().indexOf(this.searchText.toLocaleLowerCase().trim()) < 0) {
            item.show = false;
          } else {
            item.show = true;
            if (!item.children) {
              showNum++;
              if (item.status === true) {
                haveChoose = true;
              } else {
                haveNoChoose = true;
              }
            }
          }
          if (item.children) {
            item.children.forEach((child) => {
              if (child.name.toLocaleLowerCase().indexOf(this.searchText.toLocaleLowerCase().trim()) < 0) {
                child.show = false;
              } else {
                child.show = true;
                showNum++;
                if (child.status === true) {
                  haveChoose = true;
                } else {
                  haveNoChoose = true;
                }
              }
            });
          }
        });
        if (showNum > 0) {
          if (haveChoose) {
            if (haveNoChoose) {
              this.allChoose = false;
              this.someoneChoose = true;
            } else {
              this.someoneChoose = false;
              this.allChoose = true;
            }
          } else {
            this.allChoose = false;
            this.someoneChoose = false;
          }
        } else {
          this.someoneChoose = false;
          this.allChoose = false;
        }
      }
      this.$forceUpdate();
    },
    deleteSelfFilter(btn, bIndex) {
      //删除当前自定义内容

      let index = this.pageData.valueArr.find((item) => {
        return item.value === this.currentData[bIndex].value;
      });
      this.currentData.splice(bIndex, 1);
      this.pageData.valueArr.splice(index, 1);
      this.$forceUpdate();
    },
    addNewfilter() {
      //添加自定义条件
      if (!this.saveContent) {
        return;
      }
      let obj = {};
      if (this.popData?.children[0]?.children) {
        obj = {
          name: this.saveContent,
          value: this.saveContent,
          self: true,
          children: [
            {
              name: this.saveContent,
              value: this.saveContent
            }
          ]
        };
      } else {
        obj = {
          self: true,
          name: this.saveContent,
          value: this.saveContent
        };
      }
      this.pageData.valueArr.unshift(this.deepClone(obj));
      this.currentData.unshift(obj);
    },
    showText() {
      //显示文字
      let data = this.pageData.valueArr;
      let arr = [];
      data.forEach((item) => {
        if (item.children) {
          arr.push(item.children.map((item) => item.name).join(" " + this.lanShow("And") + " "));
        } else {
          arr.push(item.name);
        }
      });
      return arr.join(" " + this.lanShow("And") + " ");
    },
    contains() {
      if (document.documentElement.contains) {
        return function (refNode, otherNode) {
          return refNode.contains(otherNode);
        };
      } else if (document.documentElement.compareDocumentPosition) {
        return function (refNode, otherNode) {
          return !!(refNode.compareDocumentPosition(otherNode) & 16);
        };
      } else {
        return function (refNode, otherNode) {
          var node = otherNode.parentNode;
          do {
            if (node === refNode) {
              return true;
            } else {
              return;
            }
          } while (node !== null);
        };
      }
    }
  }
};
</script>

<style lang="less">
@rem: 0.01rem;
.pop {
  width: 450 * @rem !important;
}
.search-group {
  position: relative;
  .close-btn {
    position: absolute;
    top: -4 * @rem;
    right: -8 * @rem;
    padding: 5px;
    .el-button {
      width: 15 * @rem;
      height: 15 * @rem;
    }
  }
  .popBtn {
    cursor: pointer;
    padding: 1px;
    outline-color: transparent !important;
    outline-style: none !important;
  }
  .addBtn {
    display: block;
    width: 22 * @rem;
    height: 22 * @rem;
    background: #eff1f3c2;
    border-radius: 22 * @rem;
    // margin: 0px 5px;
    margin-left: 2px;
    background-image: url("../assets/common/add.svg");
    background-repeat: no-repeat;
    background-size: 10 * @rem 10 * @rem;
    background-position: center center;
  }
  .condition-show:hover {
    background: #d6e3f0;
  }
  .addBtn:hover {
    background-color: #d6e3f0;
  }
  .condition-show {
    font-size: 12px;
    max-width: calc(100vw - 330px);
    overflow: hidden;
    // margin: 3px 2px;
    background: #eff1f3;
    height: 22 * @rem;
    line-height: 22 * @rem;
    border-radius: 25 * @rem;
    padding: 0px 18px 0 10px;
    color: rgb(53, 120, 229);
    div {
      overflow: hidden;
    }
  }
}

.condition-container {
  display: flex;
  font-size: 12px;
  .save-conditions {
    width: 160 * @rem;
    .saved-box {
      .saved-item {
        line-height: 20px;
        padding: 10px 4px 10px 8px;
        cursor: pointer;
        overflow: scroll;
        .close-icon {
          display: none;
          font-family: iconfont;
          float: right;
          margin-right: 10px;
        }
      }
      .saved-item:hover {
        // box-shadow: 0 0 2px #ededed inset;
        background: #edf3f9;
      }
      .saved-item:hover > .close-icon {
        display: inline-block;
      }
    }
  }
  .new-conditions {
    flex: 1;
    border-left: 1 * @rem solid #ededed;
    .conditions-container {
      background: #fff;
      height: 300 * @rem;
      overflow-y: scroll;
      line-height: 25 * @rem;
      .input-container {
        padding: 2px;
      }
      .con-title {
        padding-left: 10 * @rem;
        padding: 5 * @rem 0;
        padding-left: 5 * @rem;
        background: #f9fcff;
        font-weight: 700;
        cursor: pointer;
        border-bottom: 1 * @rem solid #e3ecf5;
        border-top: 1 * @rem solid #e3ecf5;
      }
      .con-item:first-child .con-title {
        border-top: none;
      }
      .con-label {
        padding: 2 * @rem 0;
        padding-left: 20 * @rem;
        padding-right: 5 * @rem;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        span {
          width: calc(100% - 30px);
        }
        .icon {
          float: right;
        }
      }
      .con-step1 {
        padding: 2 * @rem;
        padding-left: 14 * @rem;
        padding-right: 5 * @rem;
      }
      .con-label:hover {
        background: #edf3f9;
      }
    }
    .regTime {
      margin-left: 15px;
      margin-top: 10px;
    }
  }
  .title {
    display: flex;
    padding: 0 10 * @rem;
    justify-content: space-between;
    align-items: center;
    height: 32 * @rem;
    font-size: 12 * @rem;
    background: #f9fcff;
    border-bottom: 1 * @rem solid #ededed;
    .search-input {
      width: 110px;
      height: 24 * @rem;
      border: 1 * @rem solid #edededb4;
      border-radius: 15 * @rem;
      padding: 0 15 * @rem;
    }
    input {
      background: none;
      outline: none;
    }
    input:focus {
      border: 1 * @rem solid #dcdfe6;
    }
  }
}
// @media screen and(min-width: 501px) {
//   .search-group {
//     .close-btn {
//       display: none;
//     }
//     &:hover > .close-btn {
//       display: block;
//     }
//   }
// }
@media screen and (max-width: 500px) {
  .search-group {
    .condition-show {
      max-width: calc(90vw - 55px);
    }
  }
  .pop {
    width: 300 * @rem !important;
  }
  .save-conditions {
    display: none;
  }
}
</style>
