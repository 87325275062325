<template>
  <div class="echarts-c-container">
    <div class="echarts-title-box" :class="data.titleType" v-show="!data.hideTitle">
      {{ this.lanShow(data.title) }}
      <span v-if="data.title" class="des-icon cursor" @click="desShow" />
    </div>
    <div class="echarts-item" ref="echarts-item" :style="'height:' + height + 'px;min-height:1px;min-width:1px'" v-loading="loadingStatus"></div>
    <contrast-dialog :diaStatus="diaStatus" :data="data" @diaStatusChange="diaStatusChange"></contrast-dialog>
  </div>
</template>

<script lang="ts">
import * as echarts from "echarts";
import ContrastDialog from "./contrast-dialog.vue";
import { mapState } from "vuex";
import ResizeObserver from "resize-observer-polyfill";

interface IEchartsData {
  load: boolean;
  title?: string;
  url?: string; //一般为该数据请求的接口
  value: {
    data: {
      //echarts数据
      categories: Array<any>; //x轴
      series: Array<{ name: string; data: Array<any> }>; //y轴数据
      description?: string;
      key?: string;
      link?: string;
    };
  };
  option: any; //echarts配置
  [k: string]: any;
}

export default {
  props: ["data", "height"],
  components: {
    ContrastDialog
  },
  data() {
    this.echartsInstance = null;
    return {
      loadingStatus: true,
      diaStatus: false,
      initNum: null
    };
  },
  watch: {
    "data.value": {
      //监听data变化重绘echarts组件 value和option变化都会重绘echarts 延迟10ms 防止运行两次影响性能
      deep: true,
      handler: function () {
        if (this.initNum) {
          return;
        }
        this.initNum = setTimeout(() => {
          if (this.data.form_type === "11") {
            this.echartsInstance.clear();
            this.initPie(); //详细数据饼状图页面
          } else {
            this.echartsInstance.clear();
            this.eventInit();
            this.echartsDataRender(this.data);
            this.initNum = null;
          }
          this.echartsRestore();
        }, 10);
      }
    },
    "data.option": {
      deep: true,
      handler: function () {
        if (this.initNum) {
          return;
        }
        this.initNum = setTimeout(() => {
          if (this.echartsInstance) {
            this.echartsInstance.clear();
            this.eventInit();
            this.echartsDataRender(this.data);
            this.initNum = null;
          }
        });
      }
    },
    "data.load": {
      deep: true,
      handler: function (val) {
        this.loadingStatus = val;
      }
    }
  },
  computed: mapState({
    minScreen: "minScreen" //手机上的对比功能.暂时不开放
  }),
  mounted() {
    let container = this.$refs["echarts-item"];
    let fun = this.throttle(() => {
      //节流优化
      this.echartsInstance.resize();
    }, 300);
    const ro = new ResizeObserver((a) => {
      if (a[0].contentRect.width === 0) {
        //当前场景下盒子的取值宽度变成0 说明display：none
        return;
      }
      fun();
    });
    ro.observe(container);
    this.loadingStatus = this.data.load;
    if (container.offsetHeight === 0 && container.offsetWidth === 0) {
      let parentNode = container.parentNode;
      document.body.appendChild(container);
      this.$nextTick(() => {
        this.echartsInstance = echarts.init(container);
        parentNode.appendChild(container);
      });
    } else {
      this.echartsInstance = echarts.init(container);
    }
  },
  methods: {
    /**显示简介 */
    desShow() {
      let html = this.data.value.description;
      if (this.data.value.key) {
        html = this.lanShow(this.data.value.key);
        if (this.data.value.updateDescription) {
          html += "<p>" + this.data.value.updateDescription + "</p>";
        }
        if (this.data.value.link) {
          html += '<a href="' + this.data.value.link + '" target="_blank"">更多</a>';
        }
      }
      this.$alert(html || this.lanShow("NoIntroduce"), this.lanShow("Introduce"), {
        dangerouslyUseHTMLString: true,
        showCancelButton: false,
        showConfirmButton: false,
        closeOnClickModal: true
      }).catch(() => {});
    },
    /**对比弹窗状态改变 */
    diaStatusChange(status: boolean) {
      this.diaStatus = status;
    },
    /**饼图 可以考虑弄一个新组件？*/
    initPie() {
      if (this.data.value) {
        this.echartsInstance.setOption({
          series: [{ data: this.data.value.data }]
        });
      }
    },
    /**echart数据渲染 */
    echartsDataRender(data: IEchartsData) {
      if (data.value?.data?.series) {
        this.loadingStatus = false;
        let opt = this.echartsInstance.getOption();
        let dataSeriesArr = data.value.data.series;
        if (opt.title[0].text !== "") {
          //代表不是第一次
          let seriesArr = opt.series; //获取上一次渲染的数据
          if (dataSeriesArr.length === 0) {
            //未获取到数据 清空图表
            seriesArr.forEach((item: any) => {
              item.data = [];
            });
            this.echartsInstance.setOption({
              series: seriesArr
            });
            return;
          } else if (seriesArr.length > dataSeriesArr.length) {
            //当返回数据series length变少时,将未获得数据置空
            seriesArr.forEach((item: any) => {
              item.data = [];
              let obj = dataSeriesArr.find((v: any) => {
                return v.name === item.name;
              });
              if (obj) {
                item.data = obj.data;
              }
            });
            this.echartsInstance.setOption({
              series: seriesArr
            });
          }
        }

        //是否有双y轴
        let yIndexDouble = false;
        //处理渲染的series数据
        let renderArr = dataSeriesArr.map((item: any) => {
          if (item.yAxisIndex == 1) {
            yIndexDouble = true;
          }
          let obj = { ...data.option.series[0], ...item };
          //线形图不叠加
          if (item.yAxisIndex == "1" && data.option.series[0].stack === "one" && obj.type === "line") {
            delete obj.stack;
          }
          return obj;
        });

        /**将父组件种类选中状态赋值给新图表 不然默认全部显示*/
        let selectObj = {};
        if (data.option.legend.selected && JSON.stringify(data.option.legend.selected) !== "{}") {
          selectObj = data.option.legend.selected;
        } else {
          renderArr.forEach((item: any) => {
            selectObj[item.name] = true;
          });
        }

        let xArr = data.value.data.categories || [];
        let otherOption = {};
        //横向柱状图额外处理 用于自定义页面内的组件
        if (this.data.form_type === "32") {
          if (xArr.length > 30 && this.data.showDataZoom) {
            otherOption = {
              animation: false,
              dataZoom: [
                {
                  type: "slider",
                  yAxisIndex: [0, 1],
                  filterMode: "none",
                  width: 20
                }
              ]
            };
          }
          this.echartsInstance.setOption({
            legend: [
              {
                selected: data.option.legend.show ? selectObj : {}
              }
            ],
            title: {
              text: data.title
            },
            yAxis: yIndexDouble ? [{ data: xArr }, { data: xArr }] : { data: xArr }, //如果有双y轴则要赋值两个y轴
            series: renderArr,
            grid: {
              right: this.data.option.grid.right != 0 ? (yIndexDouble ? 80 : 20) : 0
            },
            ...otherOption
          });
        } else {
          if (xArr.length > 30 && this.data.option.toolbox && this.data.showDataZoom) {
            otherOption = {
              animation: false,
              dataZoom: [
                {
                  type: "slider",
                  bottom: 0,
                  height: 20
                }
              ]
            };
          }
          this.echartsInstance.setOption({
            legend: [
              {
                selected: data.option.legend.show ? selectObj : {}
              }
            ],
            title: {
              text: data.title
            },
            xAxis: [{ data: xArr }],
            series: renderArr,
            grid: {
              right: this.data.option.grid.right != 0 ? (yIndexDouble ? 50 : 20) : 0
            },
            ...otherOption
          });
        }
      }
    },
    echartsRestore() {
      this.$nextTick(() => {
        this.echartsInstance.resize();
      });
    },
    eventInit() {
      let option = this.deepClone(this.data.option);

      if (this.$store.getters["getDownload"] !== 1) {
        if (option.toolbox?.feature?.mydownload) {
          option.toolbox.feature.mydownload.show = false;
        } //鉴定下载权限
      }

      if (option.toolbox?.feature?.myRestore) {
        //重新绑定restore事件
        option.toolbox.feature.myRestore.onclick = () => {
          this.loadingStatus = true;
          this.$emit("restoreEcharts", this.data);
        };
      }
      if (option.toolbox?.feature?.myContrast) {
        //重新绑定对比事件
        if (this.minScreen) {
          delete option.toolbox.feature.myContrast;
          //手机上没有对比
        } else {
          option.toolbox.feature.myContrast.onclick = () => {
            this.diaStatus = true;
          };
        }
      }
      if (option.toolbox?.feature?.myReverse) {
        option.toolbox.feature.myReverse.onclick = () => {
          //绑定反转事件
          let instance = this.echartsInstance.getOption();
          let selectObj = this.deepClone(instance.legend[0].selected);
          for (let k in selectObj) {
            selectObj[k] = !selectObj[k];
          }
          this.echartsInstance.setOption({
            legend: [{ selected: selectObj }]
          });
        };
      }
      this.echartsInstance.setOption({
        ...option,
        title: { ...this.data.option.title, text: this.data.title }
      });
      this.echartsRestore();
    }
  }
};
</script>

<style lang="less">
@rem: 0.01rem;

.echarts-c-container {
  position: relative;

  .echarts-item {
    width: 100%;
    // position: relative;
    height: 320 * @rem;
    font-size: 0px;
  }

  .echarts-title-box {
    z-index: 10;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 14px;
    font-weight: bold;
    color: #666;

    .des-icon {
      display: inline-block;
      width: 18px;
      height: 18px;
      background: url(@/assets/common/des.svg);
      background-size: 100% 100%;
      transform: translate(0px, 3px);
    }

    .ad-update-time {
      font-size: 12px;
      font-weight: normal;
      vertical-align: middle;
      pointer-events: none;
      color: #222222;
    }
  }

  .incomeAnalysis {
    top: 6px;
    left: 6px;
  }

  .userGroup {
    left: 16px;
  }
}

.echarts-c-container {
  textarea {
    line-height: 16px !important;
    box-sizing: border-box;
  }

  textarea:focus-visible {
    outline: none;
  }
}
</style>
