export const reqEditApi: { [k: string]: boolean } = {
  "custom/addpage": true,
  "custom/editpage": true,
  "custom/delpage": true,
  "custom/adddata": true,
  "custom/editdata": true,
  "custom/deldata": true,
  "custom/addform": true,
  "custom/editform": true,
  "custom/delform": true,
  "custom/setformsort": true,
  "custom/setformformlayouts": true,
  "custom/actionimportfromjson": true,
  "custom/oldconvert": true
};
export const reqApi: { [k: string]: boolean } = {
  "newgraph/metricstable_m3": true,
  "retention/getretentionavg": true,
  "custom/gettimezone": true,
  "custom/getalldata": true,
  "custom/actionexporttojson": true,
  "custom/conditionsoperation": true,
  "util/getallfilters": true,
  "realtime/realtime": true,
  "dau/daudnuinall": true,
  "dnu/dnubycountries": true,
  "dau/daubycountries": true,
  "dnu/dnubyadchannelname": true,
  "dau/daubyadchannelname": true,
  "dau/daubyappversionbydays": true,
  "dau/dailydaynuser": true,
  "dnu/dnubydays": true,
  "dau/daubydays": true,
  "dnu/dnubyadsource": true,
  "dau/daubyadsource": true,
  "dnu/dnubyconvertunionname": true,
  "util/clearcacheall": true,
  "retention/retentionbydays": true,
  "retention/retentionbycountry": true,
  "retention/retentionbyplatform": true,
  "iap/iapdata": true,
  "iap/revaccbydays": true,
  "iap/puaccbydays": true,
  "iap/conversionbyday": true,
  "iap/adrevenueaccbychannelbydays": true,
  "iap/arppubyday": true,
  "iap/arpdaubyday": true,
  "iap/adarpdau": true,
  "iap/revenuebydays": true,
  "iap/adrevenuebychannelbydays": true,
  "iap/paydaynbydays": true,
  "iap/revdaynbydays": true,
  "iap/payamountgrouptoday": true,
  "iap/packagecountbydays": true,
  "iap/payamountgroupinall": true,
  "iap/paycountgroupinall": true,
  "iap/seeadnumberpeople": true,
  "iap/seeadrevenue": true,
  "iap/revenuebycountry": true,
  "iap/revaccbycountries": true,
  "iap/puaccbycountries": true,
  "iap/conversionbycountry": true,
  "iap/arppubycountry": true,
  "iap/arpdaubycountry": true,
  "dailypurchaseduser/purchaseduserconditions": true,
  "dailypurchaseduser/purchaseduser": true,
  "newgraph/metricstablednu": true,
  "newgraph/metricstabledau": true,
  "linetable/getlinetable": true,
  "linetable/charttarget": true,
  "addot/advertisingdetaildata": true,
  "addot/getfilename": true,
  "addot/advertisingdatabyiapandad": true,
  "level/leveltable": true,
  "custom/getallpage": true,
  "custom/getform": true,
  "package/packagepercentrank": true,
  "package/packagerate": true,
  "package/getpackagecount": true,
  "dau/activecharturl": true
};
