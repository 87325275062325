<template>
  <div class="date-picker">
    <el-date-picker
      :shortcuts="shortcuts"
      :editable="false"
      :disabled-date="disabledDate"
      v-model="timeArr"
      format="YYYY/MM/DD"
      type="daterange"
      :offset="offset"
      placement="bottom-start"
      id="date-picker"
      unlink-panels
      :popper-class="timeClass"
      :start-placeholder="lanShow('StartTime')"
      :end-placeholder="lanShow('EndTime')"
      @change="timeChangeSearch"
      :clearable="false">
    </el-date-picker>
    <el-icon class="date-arrow"><CaretRight /></el-icon>
  </div>
</template>
<script setup>
import { ref, onMounted } from "vue";
import { nextTick, getCurrentInstance } from "vue";
const STORE_DATE_KEY = "TimeArrayStored";
const offset = 10;
const props = defineProps({
  init: Boolean,
  popperClass: String
});
const emit = defineEmits(["pick", "timeChange"]);
const { proxy } = getCurrentInstance();

const timeArr = ref("");
const shortcuts = [
  {
    text: proxy.lanShow("Today"),
    value: getLastDaysRange(0)
  },
  {
    text: proxy.lanShow("Yesterday"),
    value: getLastDaysRange(1)
  },
  {
    text: proxy.lanShow("LastWeek"),
    value: getLastDaysRange(7)
  },
  {
    text: proxy.lanShow("Last14Days"),
    value: getLastDaysRange(14)
  },
  {
    text: proxy.lanShow("Last30Days"),
    value: getLastDaysRange(30)
  },
  {
    text: proxy.lanShow("Last60Days"),
    value: getLastDaysRange(60)
  },
  {
    text: proxy.lanShow("Last90Days"),
    value: getLastDaysRange(90)
  },
  {
    text: proxy.lanShow("ThisMonth"),
    value: () => {
      const start = new Date();
      start.setTime(new Date(new Date().getFullYear() + "/" + (new Date().getMonth() + 1) + "/01"));
      let end = new Date();
      return [start, end];
    }
  },
  {
    text: proxy.lanShow("LastMonth"),
    value: () => {
      let curMonthOne =
        new Date(new Date().getFullYear() + "/" + (new Date().getMonth() + 1) + "/01").getTime() - 24 * 60 * 60 * 1000;
      let end = new Date(curMonthOne);
      const start = new Date();
      start.setTime(new Date(new Date(end).getFullYear() + "/" + (new Date(end).getMonth() + 1) + "/01"));
      return [start, end];
    }
  }
];
const disabledDate = (time) => {
  return time.getTime() > Date.now();
};
let timeClass = ref();

onMounted(() => {
  if (props.init) {
    initTime();
  }
  if (props.popperClass) {
    timeClass.value = props.popperClass;
  }
});

function getLastDaysRange(days) {
  return () => {
    const end = new Date();
    const start = new Date() - 3600 * 1000 * 24 * days;
    return [start, end];
  };
}

function timeChangeSearch() {
  //转换时间戳
  let arr = timeArr.value.map((item) => {
    let t = new Date(item).getTime();
    return t;
  });
  sessionStorage.setItem(STORE_DATE_KEY, JSON.stringify(arr));
  emit("timeChange", arr);
}
function initTime() {
  // 如果本地有缓存就用本地 不然其他页面默认昨天前七天
  let time = sessionStorage.getItem(STORE_DATE_KEY);
  if (time) {
    timeArr.value = JSON.parse(time).map((item) => new Date(item));
  } else {
    const end = new Date();
    const start = end - 3600 * 1000 * 24 * 7;
    timeArr.value = [start, end];
  }
  nextTick(() => {
    timeChangeSearch();
  });
}
</script>
<style lang="less">
@rem: 0.01rem;
.date-picker {
  min-width: 245 * @rem;
  margin-left: -10 * @rem;
  white-space: nowrap;
}
.el-date-editor {
  * {
    cursor: pointer;
  }
}
.el-date-editor:hover,
.el-range-editor.is-active:hover,
.el-range-editor.el-input__wrapper {
  width: 185 * @rem !important;
  box-shadow: none !important;
  border: none !important;
}
.el-input__icon.el-range__icon {
  margin-right: 3px;
  margin-top: -2px;
  // width: 20 * @rem !important;
}
.el-range__close-icon--hidden {
  display: none !important;
}
.el-date-editor .el-range-separator {
  flex: 0;
  color: #7b7c7d;
}
.el-date-editor .el-range-input,
.date-picker .el-icon {
  color: #7b7c7d;
}
.el-picker-panel .el-picker-panel__shortcut {
  line-height: 28 * @rem;
  font-size: 13 * @rem;
}
@media screen and (max-width: 500px) {
  .el-picker-panel__sidebar {
    display: none;
  }
  .el-picker-panel__body {
    min-width: 300 * @rem !important;
    margin-left: 0 !important;
  }
  .el-date-range-picker__content.is-left {
    width: 100% !important;
  }
  .el-date-range-picker__content.is-right {
    display: none !important;
  }
  .el-date-range-picker.has-sidebar {
    width: 100% !important;
  }
}
</style>
