import axios from "axios";
import type { AxiosResponse } from "axios";
import data from "@/store";
import { ElMessage as Message } from "element-plus";
import { judgeType } from "../common";
import { lanData } from "../lan/language";
import { langTypeEmum } from "../projectTypeDefine";
import { app } from "@/main";
import router from "@/router";

const lanType = localStorage.getItem("lan") as langTypeEmum;
const currentLan = lanData[lanType || "cn"];
axios.defaults.baseURL = "/";
const reg = /appId=\w+/;

export interface resData {
  msg: keyof typeof currentLan;
  code?: any;
  data: {
    string?: any;
  }; // 服务端返回的数据
}

// export interface AxiosRequestConfig { }

axios.interceptors.request.use(
  function (config) {
    // 在发送请求之前做些什么
    if (import.meta.env.DEV) {
      //本地请求需要走/api本地服务器发送请求
      config.baseURL = "/api";
    }
    const arr = config.url.split("?");
    //历史需求 有两种接口改成这里的url
    const { reqApi, reqEditApi } = app.config.globalProperties.$_interType;
    if (reqEditApi[arr[0]]) {
      config.data.url = arr[0];
      arr[0] = "report/edit-api";
    } else if (reqApi[arr[0]]) {
      config.data.url = arr[0];
      arr[0] = "report/api";
    }
    if (arr[1]) {
      arr[1] = arr[1].replace(reg, "appId=" + data.state.appId);
    } else if (!arr[1] && data.state.appId) {
      arr[1] = "appId=" + data.state.appId;
    }
    config.url = arr.join("?");

    return config;
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 添加响应拦截器
axios.interceptors.response.use(
  function (response: AxiosResponse<resData>) {
    // 对响应数据做点什么
    if (typeof response.data === "object" && "code" in response.data) {
      if (response.data.code === 0) {
        return Promise.resolve(response);
      } else if (response.data.code === 40001) {
        Message({
          message: currentLan["NeedLogin"],
          type: "warning",
          duration: 4 * 1000
        });
        router.push("/login");
      } else if (response.data.code === 40003) {
        if (router.currentRoute.value.fullPath === "/realtime_data") {
          router.push("/login");
        } else {
          router.push("/");
        }
      } else {
        const msg = currentLan[response.data.msg];
        Message({
          message: (msg && msg.slice(0, 40)) || currentLan["ReqFail"],
          type: "warning",
          duration: 3 * 1000
        });
        return Promise.reject(response);
      }
    } else {
      return Promise.resolve(response);
    }
  },
  function (data) {
    console.log(data, "---");
    // 对响应错误做点什么
    Message({
      message: currentLan["ReqFail"],
      type: "error",
      duration: 4 * 1000
    });
  }
);

export function myboGet() {
  return function (url: string, data: { string?: any } = {}, config: any) {
    let str = "";
    if (data && Object.keys(data).length !== 0) {
      for (const k in data) {
        str += k + "=" + data[k] + "&";
      }
      const arr = str.split("");
      arr.pop();
      str = arr.join("");
    }
    const useUrl = url + "?" + str;
    return axios.get(useUrl, config);
  };
}
export function myboPost() {
  return function (url: string, data = {}, config?: any) {
    return axios.post(url, data, config);
  };
}
export function myboForm() {
  return function (url: string, cdata: any = {}, config = {}) {
    if (cdata["appId"]) {
      cdata["appId"] = data.state.appId;
    }
    const fd = new FormData();

    dealObj(cdata, fd);
    return axios.post(url, fd, config);
  };
}
function dealObj(obj: { appId: string }, fd: FormData) {
  for (const k in obj) {
    const type = judgeType(obj[k]);
    if (type === "array" || type === "object") {
      dealObjChild(obj[k], fd, k);
    } else {
      fd.append(k, obj[k]);
    }
  }
}
function dealObjChild(obj: { appId: string }, fd: FormData, name: string) {
  const str = name;
  for (const k in obj) {
    const type = judgeType(obj[k]);
    if (type === "array" || type === "object") {
      dealObjChild(obj[k], fd, str + `[${k}]`);
    } else {
      fd.append(str + `[${k}]`, obj[k]);
    }
  }
}
