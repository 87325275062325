const routes = [
  {
    path: "/",
    redirect: "/realtime_data"
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/login/index.vue")
  },
  {
    path: "/password",
    name: "Password",
    component: () => import("../views/password/index.vue")
  },
  {
    path: "/home",
    name: "Home",
    component: () => import("../views/menu-frame/index.vue"),
    children: [
      {
        path: "/realtime_data",
        name: "realData",
        meta: ["RealtimeData"],
        component: () => import("../views/realtime-data/index.vue")
      },
      {
        path: "/active_user",
        name: "activeUser",
        meta: ["ActiveUsers"],
        component: () => import("../views/analytics/active-user/index.vue")
      },
      {
        path: "/daily_report",
        name: "dataAnalysis",
        meta: ["DailyReport"],
        component: () => import("../views/analytics/daily-report/index.vue")
      },
      {
        path: "/daily_report_m3",
        name: "dataAnalysis_m3",
        meta: ["DailyReport"],
        component: () => import("../views/analytics/daily-report-m3/index.vue")
      },
      {
        path: "/revenue",
        name: "incomeAnalysis",
        meta: ["Revenue"],
        component: () => import("../views/analytics/revenue/index.vue")
      },
      {
        path: "/retention",
        name: "userRetain",
        meta: ["Retention"],
        component: () => import("../views/analytics/retention/index.vue")
      },
      {
        path: "/cohort",
        name: "userGroup",
        meta: ["Cohort"],
        component: () => import("../views/analytics/cohort/index.vue")
      },
      {
        path: "/ua_data",
        name: "resourceReport",
        meta: ["UAData"],
        component: () => import("../views/analytics/ua-report/index.vue")
      },
      {
        path: "/ua_data_ad",
        name: "resourceReportAdvertise",
        meta: ["UADataAD"],
        component: () => import("../views/analytics/ua-report/index.vue")
      },
      {
        path: "/ua_data_os",
        name: "uaReportOverseas",
        meta: ["UAData"],
        component: () => import("../views/analytics/ua-report-overseas/index.vue")
      },
      {
        path: "/ua_data_merge",
        name: "UADataMerge",
        meta: ["UADataMerge"],
        component: () => import("../views/analytics/ua-report-merge/index.vue")
      },
      {
        path: "/gift_bag_daily",
        name: "giftDailyReport",
        meta: ["GiftData"],
        component: () => import("../views/analytics/gift-daily-report/index.vue")
      },
      {
        path: "/level_table",
        name: "levelTable",
        meta: ["LevelTable"],
        component: () => import("../views/analytics/level-table/index.vue")
      },
      {
        path: "/view",
        name: "viewPage",
        meta: ["See"],
        component: () => import("../views/custom-analytics/view-page/index.vue")
      },
      {
        path: "/detail_data",
        name: "detailData",
        meta: ["DetailData"],
        component: () => import("../views/custom-analytics/view-echarts-page/index.vue")
      },
      {
        path: "/data_source",
        name: "dataResource",
        meta: ["DataSourceManage"],
        component: () => import("../views/custom-analytics/data-resource/index.vue")
      },
      {
        path: "/message_list",
        name: "message",
        meta: ["UserMessages"],
        component: () => import("../views/service-system/message-list/index.vue")
      },
      {
        path: "/service_manage",
        name: "proManager",
        meta: ["CustomerManage"],
        component: () => import("../views/service-system/provider-manager/index.vue")
      },
      {
        path: "/quick_response",
        name: "fastReply",
        meta: ["QuickReplyManage"],
        component: () => import("../views/service-system/fast-reply/index.vue")
      },
      {
        path: "/by_accounts",
        name: "userQuery",
        meta: ["UserQueryByAccounts"],
        component: () => import("../views/user-query/query/index.vue")
      },
      {
        path: "/by_devices",
        name: "queryMultiple",
        meta: ["UserQueryByDevices"],
        component: () => import("../views/user-query/query-multiple/index.vue")
      },
      {
        path: "/freeze_list",
        name: "freezeList",
        meta: ["FreezeList"],
        component: () => import("../views/user-query/freeze-list/index.vue")
      },
      {
        path: "/rule_manage",
        name: "rulesManage",
        meta: ["RuleManage"],
        component: () => import("../views/rules-manager/index.vue")
      },
      {
        path: "/system_settings",
        name: "settingManage",
        meta: ["SystemManage"],
        component: () => import("../views/setting/settings/index.vue")
      },
      {
        path: "/account_manage",
        name: "accountManage",
        meta: ["AccountManage"],
        component: () => import("../views/setting/account/index.vue")
      },
      {
        path: "/role_manage",
        name: "roleManage",
        meta: ["RoleManage"],
        component: () => import("../views/setting/role/index.vue")
      },
      {
        path: "/key_manage",
        name: "keyManage",
        meta: ["KeyManage"],
        component: () => import("../views/setting/key/index.vue")
      },
      {
        path: "/request_log",
        name: "changeLog",
        meta: ["Logs"],
        component: () => import("../views/setting/change-log/index.vue")
      },
      {
        path: "/iaps_manage",
        name: "commodityManagement",
        meta: ["Catalog"],
        component: () => import("../views/commodity-manage/view/index.vue")
      },
      {
        path: "/goods_details",
        name: "productDetail",
        meta: ["GoodsDetails"],
        component: () => import("../views/commodity-manage/product-detail/index.vue")
      },
      {
        path: "/team_manage",
        name: "teamManage",
        meta: ["TeamManage"],
        component: () => import("../views/team-manage/manage/index.vue")
      },
      {
        path: "/team_leaderboard",
        name: "teamList",
        meta: ["TeamRank"],
        component: () => import("../views/team-manage/list/index.vue")
      },
      {
        path: "/sensitive_word_management",
        name: "SensitiveWord",
        meta: ["SensitiveWord"],
        component: () => import("../views/sensitive-manage/index.vue")
      },
      {
        path: "/image_manage",
        name: "pictureManage",
        meta: ["PicturesManage"],
        component: () => import("../views/picture-manage/manage/index.vue")
      },
      {
        path: "/photo_album",
        name: "albumItem",
        meta: ["Album"],
        component: () => import("../views/picture-manage/photo-album/index.vue")
      },
      {
        path: "/push_manage",
        name: "pushManage",
        meta: ["PushManage"],
        component: () => import("../views/push-manage/index.vue")
      },
      {
        path: "/config_file",
        name: "profileManage",
        meta: ["ConfigManage"],
        component: () => import("../views/profile-manage/index.vue")
      },
      {
        path: "/file_compare",
        name: "profileCompare",
        meta: ["FileCompare"],
        component: () => import("../views/profile-manage/profile-compare.vue")
      },
      {
        path: "/label_manage",
        name: "labelManager",
        meta: ["TagManage"],
        component: () => import("../views/service-system/lebel-manager/index.vue")
      },
      {
        path: "/vip_list",
        name: "vipList",
        meta: ["VipList"],
        component: () => import("../views/service-system/vip-list/index.vue")
      },
      {
        path: "/e404",
        name: "err",
        meta: ["ErrorPage"],
        component: () => import("../views/err/index.vue")
      }
    ]
  }
];

import { createRouter, createWebHashHistory } from "vue-router";

const router = createRouter({
  history: createWebHashHistory(import.meta.env.BASE_URL),
  routes: routes
});

router.beforeEach((to, from, next) => {
  if (!to.name) {
    next({ name: "err" });
  }
  next();
});

export default router;
