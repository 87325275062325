<template>
  <div class="contrast-containier" v-if="diaStatus" ref="diaOrigin">
    <div class="title-box">{{ diaObj.diaName }}</div>
    <div class="icon-box">
      <div v-if="!minScreen">
        <span class="btn-box" v-show="sizeStatus" @click.stop="toSmall">&#xe87b;</span>
        <span class="btn-box" v-show="!sizeStatus" @click.stop="toBigger">&#xe6a7;</span>
      </div>
      <div class="close_btn" @click.stop="changeDiaStatus">&#xe61e;</div>
    </div>
    <div class="contrast-content no-bar">
      <div class="left-box">
        <div class="echarts-box">
          <echarts-com v-if="diaObj" ref="echartsItem" :data="diaObj" />
        </div>
        <div class="search-container">
          <div class="search-header">
            <div class="time-container">
              <time-com @timeChange="filterTimeGet" :init="true"></time-com>
            </div>
            <div class="btn-group">
              <el-button @click="addConodition">
                {{ lanShow("AddContrast") }}
              </el-button>
              <el-button @click="clearEsData">
                {{ lanShow("ClearESSearch") }}
              </el-button>
              <el-button @click="searchAllData">{{ lanShow("SearchAll") }} </el-button>
            </div>
          </div>
          <div class="search-body">
            <div class="group-item" v-for="(item, index) in collapseArr" :key="item.index">
              <div class="group-item-title">
                <div>
                  <el-switch v-model="item.show" @change="toggleShow(index)" />
                </div>
                <div>G{{ index + 1 }}</div>
                <div class="btn-group">
                  <span class="btn-item" @click.stop="deleteItem(index)">&#xe61e;</span>
                </div>
              </div>
              <div>
                <condition :index1="index" :data="item.condition" @sendData="getChildData" @searchStart="getItemData" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="right-box">
        <div class="radio-title">{{ lanShow("EchartsType") }}</div>
        <div class="radio-box">
          <el-radio
            v-for="(item, index) in typeArr"
            :key="index"
            @change="typeChange"
            v-model="echartsType"
            :label="item.value"
            >{{ item.label }}</el-radio
          >
        </div>
        <div class="radio-title">{{ lanShow("GroupSelection") }}</div>
        <div class="choose-all">
          {{ lanShow("GroupChooseAll") }}：
          <el-switch
            v-model="chooseAllStatus"
            @change="chooseAllChange"
            active-color="#13ce66"
            inactive-color="#ff4949">
          </el-switch>
        </div>
        <div class="radio-box">
          <el-checkbox-group @change="itemDataChange" v-model="selectType">
            <el-checkbox
              @change="itemChage(item)"
              :label="item"
              v-for="(item, index) in selectArr"
              :key="index"></el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { myboPost } from "@/util/http";
import { arevConfig, positionContrast } from "@/util/echarts/configFun";
import { obj as specObj } from "@/util/echarts/spectip";
import timeCom from "./time-com.vue";
import Condition from "./condition-com.vue";
const mp = myboPost();
export default {
  components: {
    timeCom,
    Condition
  },
  computed: mapState({
    minScreen: "minScreen" //手机上的对比功能。暂时不开放
  }),
  props: ["diaStatus", "data"],
  data() {
    return {
      chooseAllStatus: true,
      echartsType: "",
      sizeStatus: true,
      timeArr: [],
      selectArr: [],
      selectType: [],
      collapseArr: [
        {
          index: 1,
          condition: {
            data: [
              {
                valueArr: [],
                scope: {
                  value: "",
                  name: ""
                },
                name: "",
                value: ""
              }
            ],
            reg: []
          },
          value: {},
          show: true,
          select: []
        }
      ],
      diaObj: {
        url: "",
        diaName: "",
        title: "",
        load: false,
        value: null,
        option: {}
      },
      typeArr: [
        { value: "lineOption", label: this.lanShow("BasisEcharts") },
        { value: "lineAreaOption", label: this.lanShow("RegionalFigure") },
        { value: "lineSmoothOption", label: this.lanShow("SmoothFigure") },
        { value: "barOption", label: this.lanShow("Histogram") }
      ]
    };
  },
  watch: {
    diaStatus(newV) {
      if (newV) {
        this.sizeStatus = true;
        let filterObj = this.$_filterData;
        let arr = this.deepClone(this.collapseArr);
        arr[0].condition = this.deepClone(filterObj);
        this.collapseArr = [];
        this.$nextTick(() => {
          this.collapseArr = arr;
          this.initData();
        });
      } else {
        this.chooseAllStatus = true;
        this.echartsType = "";
        this.sizeStatus = true;
        this.timeArr = [];
        this.selectArr = [];
        this.selectType = [];
        this.collapseArr = [
          {
            index: 1,
            condition: {
              data: [
                {
                  valueArr: [],
                  scope: {
                    value: "",
                    name: ""
                  },
                  name: "",
                  value: ""
                }
              ],
              reg: []
            },
            value: {},
            show: true,
            select: []
          }
        ];
        this.diaObj = {
          url: "",
          diaName: "",
          title: "",
          load: false,
          value: null,
          option: {}
        };
      }
    }
  },
  methods: {
    chooseAllChange() {
      if (this.chooseAllStatus) {
        this.selectType = this.deepClone(this.selectArr);
        this.collapseArr.forEach((item) => {
          item.select = item.value.data.series.map((item) => {
            return item.name;
          });
        });
      } else {
        this.selectType = [];
        this.collapseArr.forEach((item) => {
          item.select = [];
        });
      }
      console.log(this.selectType, "----->");
      this.itemDataChange();
    },
    searchAllData() {
      let arr = [];
      this.collapseArr.forEach((data) => {
        data = data.condition;
        let obj = {};
        data.data.forEach((item) => {
          if (item.valueArr.length !== 0) {
            if (item.scope.value === "include") {
              obj[item.value] = item.valueArr;
            } else {
              obj[item.value + "_" + item.scope.value] = item.valueArr;
            }
          }
        });
        if (data.reg.length !== 0) {
          let t = data.reg;
          obj["register_time"] = [
            { value: t[0], name: "注册开始时间" },
            { value: t[1], name: "注册结束时间" }
          ];
        }
        let params = {
          ...this.data.param,
          start: this.timeArr[0],
          end: this.timeArr[1],
          filter: obj
        };
        if (Object.keys(obj).length === 0) {
          delete params.filter;
        }

        this.diaObj.load = true;
        arr.push(mp(this.diaObj.url + "?appId=mybo_flow_cn", params));
      });
      Promise.all(arr)
        .then((res) => {
          res.forEach((res, index) => {
            this.diaObj.load = false;
            this.collapseArr[index].value = res.data;
            let series = res.data.data.series;
            if (this.diaObj.arev) {
              arevConfig(series);
            }
            series = series
              .map((item) => {
                if (item.name === "all") {
                  return;
                }
                return {
                  ...item,
                  name: item.name + "(G" + (index + 1) + ")"
                };
              })
              .filter((item) => item);
            this.collapseArr[index].show = true;
            this.collapseArr[index].value.data.series = series;
            this.collapseArr[index].select = series
              .map((item) => item.name)
              .filter((item) => {
                if (this.diaObj.arev) {
                  return item.indexOf("平均值") >= 0;
                } else {
                  return true;
                }
              });
          });
          this.$message.success(this.lanShow("SearchSuccess"));
          this.seriesChange();
        })
        .catch(() => {
          this.diaObj.load = false;
        });
    },
    itemChage(name) {
      let reg = /(G[0-9])/;
      let index = /[0-9]+/.exec(reg.exec(name)[0])[0] - 1;
      if (this.collapseArr[index].select.indexOf(name) >= 0) {
        this.collapseArr[index].select = this.collapseArr[index].select.filter((item) => item !== name);
      } else {
        this.collapseArr[index].select.push(name);
      }
    },
    itemDataChange() {
      //重新计算所有series
      let allSeries = [];
      this.collapseArr.forEach((item) => {
        if (item.value.data) {
          allSeries = allSeries.concat(item.value.data.series);
        }
      });
      allSeries = allSeries.filter((item) => {
        return this.selectType.indexOf(item.name) >= 0;
      });
      this.diaObj.value.data.series = allSeries;
    },
    toggleShow(index) {
      this.collapseArr[index].show = !this.collapseArr[index].show;
      if (this.collapseArr[index].value.data) {
        this.seriesChange();
      } else {
        this.$message.warning(this.lanShow("GroupNoData"));
      }
    },
    seriesChange() {
      this.collapseArr.forEach((item, index) => {
        item.value.data &&
          item.value.data.series.forEach((child) => {
            child.name = child.name.replace(/(G[0-9])/, "G" + (index + 1));
          });
      });
      this.collapseArr.forEach((item, index) => {
        item.select.forEach((child, cindex) => {
          item.select[cindex] = item.select[cindex].replace(/(G[0-9])/, "G" + (index + 1));
        });
      });
      //重新计算所有series
      let allSeries = [];
      this.collapseArr.forEach((item) => {
        item.value.data &&
          item.value.data.series.forEach((child) => {
            allSeries.push(child);
          });
      });
      this.selectArr = [...new Set(allSeries.map((item) => item.name))];
      this.selectType = this.collapseArr
        .map((item) => {
          return item.show ? item.select : [];
        })
        .reduce((a, b) => {
          return a.concat(b);
        });
      this.selectType = [...new Set(this.selectType)];
      allSeries = allSeries.filter((item) => {
        return this.selectType.indexOf(item.name) >= 0;
      });
      this.diaObj.value.data.series = allSeries;
    },
    initData() {
      //初始化组件内部  展示数据

      let time = sessionStorage.getItem("timeArr");
      if (time) {
        this.timeArr = JSON.parse(time).map((item) => this.timeSystemFormat(item));
      } else {
        const end = this.timeSystemFormat();
        const start = this.timeSystemFormat(+new Date() - 7 * 24 * 3600 * 1000);
        this.timeArr = [start, end];
      }
      let obj = this.deepClone(this.data);
      if (obj.option.legend.selected) {
        delete obj.option.legend.selected;
      }
      obj.value.data.series = obj.value.data.series.map((item) => {
        return { ...item, name: item.name + "(G1)" };
      });
      this.diaObj = {
        ...obj,
        diaName: this.data.title,
        title: "",
        load: false
      };
      if (this.diaObj.option.tooltip.position === specObj.position) {
        this.diaObj.option.tooltip.position = positionContrast;
      }
      this.collapseArr[0].value = this.deepClone(this.diaObj.value);
      this.collapseArr[0].select = obj.value.data.series
        .map((item) => {
          return item.name;
        })
        .filter((item) => {
          if (this.diaObj.arev) {
            return item.indexOf("平均值") >= 0;
          } else {
            return true;
          }
        });
      this.seriesChange(); //整体可优化 这里为了初始化右侧checkbox组合初始数据
      if (this.diaObj.option.toolbox?.feature?.myRestore) {
        delete this.diaObj.option.toolbox.feature.myRestore;
      }
      if (this.diaObj.option.toolbox?.feature?.myContrast) {
        delete this.diaObj.option.toolbox.feature.myContrast;
      }
    },
    getItemData(data) {
      this.collapseArr[data.index].condition = data.data;
      this.getConditionEcharts(data.data, data.index);
    },
    getChildData(data) {
      this.collapseArr[data.index].condition = data.data;
    },
    getConditionEcharts(data, index) {
      let obj = {};
      data.data.forEach((item) => {
        if (item.valueArr.length !== 0) {
          if (item.scope.value === "include") {
            obj[item.value] = item.valueArr;
          } else {
            obj[item.value + "_" + item.scope.value] = item.valueArr;
          }
        }
      });
      if (data.reg.length !== 0) {
        let t = data.reg;
        obj["register_time"] = [
          { value: t[0], name: "注册开始时间" },
          { value: t[1], name: "注册结束时间" }
        ];
      }
      let params = {
        ...this.data.param,
        start: this.timeArr[0],
        end: this.timeArr[1],
        filter: obj
      };
      if (Object.keys(obj).length === 0) {
        delete params.filter;
      }

      this.diaObj.load = true;
      mp(this.diaObj.url + "?appId=mybo_flow_cn", params)
        .then((res) => {
          this.diaObj.load = false;
          if (this.data.retention) {
            res.data.data = { ...res.data.data[this.data.retention] };
          } //用户留存有接口数据解构不一样 适配
          this.collapseArr[index].value = res.data;

          let series = res.data.data.series;
          if (series.length === 0) {
            this.$message.warning("G" + (index + 1) + this.lanShow("GetNoData"));
          }
          if (this.diaObj.arev) {
            arevConfig(series);
          }

          series = series
            .map((item) => {
              if (item.name === "all") {
                return;
              }
              return { ...item, name: item.name + "(G" + (index + 1) + ")" };
            })
            .filter((item) => item);
          this.collapseArr[index].value.data.series = series;
          this.collapseArr[index].select = series
            .map((item) => item.name)
            .filter((item) => {
              if (this.diaObj.arev) {
                return item.indexOf("平均值") >= 0;
              } else {
                return true;
              }
            });
          this.seriesChange();
        })
        .catch(() => {});
    },

    addConodition() {
      this.collapseArr.push({
        index: this.collapseArr[this.collapseArr.length - 1].index + 1, //用作k
        condition: {
          data: [
            {
              valueArr: [],
              scope: {
                value: "",
                name: ""
              },
              name: "",
              value: ""
            }
          ],
          reg: []
        },
        value: {},
        show: true,
        select: []
      });
    },
    deleteItem(index) {
      if (this.collapseArr.length === 1) {
        this.$message.warning(this.lanShow("AtLeastOnConditions"));
        return;
      }
      this.$confirm(this.lanShow("AreYouSureDelete"), this.lanShow("Tips"), {
        confirmButtonText: this.lanShow("Comfirm"),
        cancelButtonText: this.lanShow("Cancel"),
        type: "warning"
      })
        .then(() => {
          this.collapseArr.splice(index, 1);
          this.$forceUpdate();
          this.seriesChange();
          this.$message.success(this.lanShow("DeleteSuccess"));
        })
        .catch(() => {});
    },
    filterTimeGet(val) {
      //保存左侧选择时间
      let newV = val.map((item) => {
        return this.timeSystemFormat(item);
      });
      this.timeArr = newV;
    },
    typeChange() {
      let option = {};
      if (this.echartsType === "lineOption") {
        option = {
          ...this.diaObj.option,
          series: [
            {
              type: "line"
            }
          ]
        };
      } else if (this.echartsType === "lineAreaOption") {
        option = {
          ...this.diaObj.option,
          series: [
            {
              type: "line",
              symbol: "none",
              areaStyle: {
                opacity: 0.15
              }
            }
          ]
        };
      } else if (this.echartsType === "lineSmoothOption") {
        option = {
          ...this.diaObj.option,
          series: [
            {
              type: "line",
              symbol: "none",
              smooth: true
            }
          ]
        };
      } else if (this.echartsType === "barOption") {
        option = {
          ...this.diaObj.option,
          series: [
            {
              //柱状图-横向
              type: "bar",
              stack: "one"
            }
          ]
        };
      }

      this.diaObj.option = option;
    },
    toSmall() {
      this.$refs["diaOrigin"].style.width = "900px";
      this.$refs["diaOrigin"].style.height = "700px";
      this.$refs["echartsItem"].echartsRestore();
      this.sizeStatus = false;
    },
    toBigger() {
      this.$refs["diaOrigin"].style.top = "50%";
      this.$refs["diaOrigin"].style.left = "50%";
      this.$refs["diaOrigin"].style.width = "100vw";
      this.$refs["diaOrigin"].style.height = "100vh";
      this.$refs["echartsItem"].echartsRestore();
      this.sizeStatus = true;
    },
    clearEsData() {
      mp("/util/clearcache?appId=mybo_flow_cn", {
        0: "/deviation/myboflow"
      })
        .then((res) => {
          this.$message.success(this.lanShow(res.data.description));
        })
        .catch(() => {});
    },
    changeDiaStatus() {
      this.$emit("diaStatusChange", false);
    }
  }
};
</script>

<style lang="less" scoped>
@rem: 0.01rem;

@font-face {
  font-family: "iconfont";
  src: url("../assets/common/iconfont.ttf") format("truetype");
}

.contrast-containier {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  z-index: 2000;
  box-shadow: 0 0 4px #868383;
  overflow: auto;
  color: #5f6061;
  background: #f1f5f9;

  .title-box {
    padding: 10px 10px 10px 20px;
    font-size: 16px;
    font-weight: bold;
    float: left;
  }

  .icon-box {
    float: right;
    font-size: 18px;
    width: 60px;
    line-height: 40px;

    & > div {
      display: inline-block;
    }
  }

  .btn-box {
    font-family: iconfont;
    cursor: pointer;
    margin-right: 10px;
  }

  .close_btn {
    font-family: iconfont;
    cursor: pointer;
  }

  .close_btn:hover {
    transition: 0.5s;
    transform: scale(1.2);
  }

  .contrast-content {
    background: white;
    height: calc(100% - 42px);
    overflow: auto;
    width: 100%;

    & > div {
      display: inline-block;
      height: 100%;
      box-sizing: border-box;
      padding: 10px;
      vertical-align: top;
    }

    .left-box {
      width: 75%;
      margin: 0;
      padding-top: 15px;

      .search-container {
        border-top: 1px solid #ededed;
        height: calc(100% - 360px);
        margin-top: 15px;

        .search-header {
          display: flex;
          justify-content: space-between;
          padding: 10px 10px;
        }

        .search-body {
          height: calc(100%);
          overflow: auto;
        }

        .time-container {
          display: inline-block;
        }

        .group-item {
          border: 1px solid #eee;
          border-radius: 5px;
          margin-bottom: 10px;
        }

        .group-item-title {
          display: flex;
          background: #e8eef599;
          justify-content: space-between;
          padding: 10px;
          font-weight: bolder;
          font-family: "iconfont";
          color: #3890ea;
        }

        .btn-group {
          font-family: "iconfont";
        }
      }
    }

    .right-box {
      width: 25%;
      border-left: 1px solid #ededed;
      height: 100%;
      overflow: auto;

      .radio-title {
        margin-top: 20px;
        margin-bottom: 5px;
        font-size: 16px;
      }

      .choose-all {
        margin-bottom: 5px;
      }
    }
  }
}
</style>
