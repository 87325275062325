export function throttle(fun: Function, wait: number) {
  const that = this;
  let timeout: ReturnType<typeof setTimeout> = null;
  let status = true;
  return function () {
    if (status) {
      fun.apply(that, arguments);
      status = false;
    } else {
      if (!timeout) {
        timeout = setTimeout(() => {
          fun.apply(that);
          timeout = null;
        }, wait);
      }
    }
  };
}
export function debounce(fn: Function, wait: number) {
  const that = this;
  let time: ReturnType<typeof setTimeout> = null;
  return function () {
    if (time) {
      clearTimeout(time);
    }
    time = setTimeout(() => {
      fn.apply(that);
    }, wait);
  };
}

/**复制文字 */
export function copyText(text: string) {
  if (text === "") {
    this.$message.warning(this.lanShow("CurrentNoWord"));
    return;
  }
  const input = document.createElement("textarea");
  input.value = text;
  input.style.width = "0px";
  input.style.height = "0px";
  document.getElementById("app").appendChild(input);
  input.select();
  document.execCommand("copy");
  document.getElementById("app").removeChild(input);
  this.$message.success(this.lanShow("CopySuccess"));
}

/** 时间格式化 国际化时间 年月日*/
export function timeSystemFormat(time = +new Date()) {
  return new Date(time + 8 * 3600 * 1000).toJSON().substr(0, 10).replace("T", " ");
}

/** 时间格式化 国际化时间 时分秒*/
export function timeSystemFormatMore(time = +new Date()) {
  if (typeof time !== "number") {
    time = new Date(time).getTime();
  }
  const date = new Date(time + 8 * 3600 * 1000);
  return date.toJSON().slice(0, 19).replace("T", " ");
}

export function timeSystemForUtc(time = +new Date()) {
  if (typeof time !== "number") {
    time = new Date(time).getTime();
  }
  const date = new Date(time)
    return date.toISOString()
}

/** 时间格式化 本地时间 年月日*/
export function timeSystemFormatUtcDay(time = +new Date()) {
  const date = new Date(time);
  return date.toJSON().slice(0, 10).replace("T", " ");
}

/**深拷贝函数*/
export function deepClone(data: any) {
  const oType = judgeType(data);
  let obj: any = null;
  if (oType == "array") {
    obj = [];
    for (let i = 0; i < data.length; i++) {
      obj.push(deepClone(data[i]));
    }
  } else if (oType == "object") {
    obj = {};
    for (const key in data) {
      // eslint-disable-next-line no-prototype-builtins
      if (data.hasOwnProperty(key)) {
        obj[key] = deepClone(data[key]);
      }
    }
  } else {
    return data;
  }
  return obj;
}

/**千分位分割,保留两位小数*/
export function formateNumber(num: number): string {
  if ((num ?? "") === "") {
    //判断是否是null或者undefined
    return "--";
  }
  num = Math.round(num * 100) / 100;
  const arr = (num + "").split(".");
  const re = /(?=(?!(\b))(\d{3})+$)/g;
  const str = arr[0].toString();
  if (arr[1]) {
    return str.replace(re, ",") + "." + arr[1];
  }
  return str.replace(re, ",");
}

/**千分位分割 不保留小数*/
export function percentNum(num: number): string {
  if (isNaN(+num)) {
    return num + "";
  }
  return Math.round(num * 10000) / 100 + "%";
}

/**表格宽度自适应计算*/
export function flexColumnWidth(str: string, tableData: Array<any>, id: string, sort: boolean) {
  str = str + "";
  const start = str.split("|")[0] + "";
  if (id) {
    str = id;
  }
  let columnContent = "";

  if (!tableData || !tableData.length || tableData.length === 0 || tableData === undefined) {
    return;
  }

  if (!start || !start.length || start.length === 0 || start === undefined) {
    return;
  }

  // 获取该列中最长的数据(内容)
  let max_temp = start + "";

  for (let i = 0; i < tableData.length; i++) {
    if (tableData[i][str] === null) {
      return;
    }
    const now_temp = tableData[i][str] + "";
    if (now_temp.length > max_temp.length) {
      max_temp = now_temp;
    }
  }
  columnContent = max_temp;
  columnContent += "";
  //此处其实只计算了字符串长度而没有计算中英文不同所带来的差值 但是如果计算单个字符 相当于要过滤表内所有字符 性能影响较大 如有需要再整一个详细版
  let flexWidth = calStrWidth(columnContent);
  const flexWidthStr = calStrWidth(start);
  flexWidth = Math.max(flexWidth, flexWidthStr);
  if (flexWidth < 40) {
    // 设置最小宽度
    flexWidth = 40;
  } else if (flexWidth > 400) {
    // 设置最小宽度
    flexWidth = 400;
  }
  if (sort) {
    flexWidth += 20;
  }
  return flexWidth + "px";
}

function calStrWidth(str: string) {
  let flexWidth = 0;
  for (const char of str) {
    if ((char >= "A" && char <= "Z") || (char >= "a" && char <= "z")) {
      // 如果是英文字符，为字符分配12个单位宽度
      flexWidth += 12;
    } else if (char >= "\u4e00" && char <= "\u9fa5") {
      // 如果是中文字符，为字符分配16个单位宽度
      flexWidth += 16;
    } else if (!isNaN(Number(char))) {
      flexWidth += 10;
    } else {
      // 其他种类字符，为字符分配10个单位宽度
      flexWidth += 12;
    }
  }
  return flexWidth;
}

import { h } from "vue";
/**表头悬浮弹框*/
export function renderTableHeader(title: string, popContent: string) {
  // h即为cerateElement
  if (!popContent) {
    return h("span", title);
  }
  return h(
    "el-popover",
    {
      props: {
        content: (function () {
          return popContent;
        })(),
        "popper-class": "table-header-popper",
        trigger: "hover",
        placement: "top"
      }
    },
    [h("span", { slot: "reference" }, title)]
  );
}

enum typeList {
  "[object Boolean]" = "[object Boolean]",
  "[object Number]" = "[object Number]",
  "[object String]" = "[object String]",
  "[object Function]" = "[object Function]",
  "[object Array]" = "[object Array]",
  "[object Date]" = "[object Date]",
  "[object RegExp]" = "[object RegExp]",
  "[object Undefined]" = "[object Undefined]",
  "[object Null]" = "[object Null]",
  "[object Object]" = "[object Object]"
}
/**类型判断 */
export function judgeType<T>(obj: Object | Array<T>): any {
  // tostring会返回对应不同的标签的构造函数
  const toString = Object.prototype.toString;
  const map: { [k in typeList]: string } = {
    "[object Boolean]": "boolean",
    "[object Number]": "number",
    "[object String]": "string",
    "[object Function]": "function",
    "[object Array]": "array",
    "[object Date]": "date",
    "[object RegExp]": "regExp",
    "[object Undefined]": "undefined",
    "[object Null]": "null",
    "[object Object]": "object"
  };
  if (obj instanceof Element) {
    return "element";
  }
  const k = toString.call(obj) as unknown as typeList;
  return map[k];
}
